import React from "react";
import Admin from "../Dashboard";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Slider,
  Stack,
  styled,
} from "@mui/material";
import { RobotoTypo } from "../../../utils/Typographies";
import theme, { customSlider } from "../../../utils/theme";
import moment from "moment";

const SchoolDetails = ({ data }) => {
  const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    padding: "16px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    marginBottom: "16px",
  }));
  console.log("data", data.StudentDetails);
  const personalDetails = [
    {
      label: "Student Name",
      value: data?.StudentDetails?.personalDetails?.name || "-",
    },
    {
      label: "Passport Name",
      value: data?.StudentDetails?.personalDetails?.passport_name || "-",
    },
    {
      label: "Religious Beliefs",
      value: data?.StudentDetails?.personalDetails?.religious_belief || "-",
    },
    {
      label: "Country Of Birth",
      value: data?.StudentDetails?.personalDetails?.country_of_birth || "-",
    },
    {
      label: "School Name",
      value: data?.StudentDetails?.personalDetails?.school_name || "-",
    },
    {
      label: "Recent Grade Attended",
      value:
        data?.StudentDetails?.personalDetails?.recent_grade_attended || "-",
    },
    {
      label: "Expected Enrolment Date",
      value:
        data?.StudentDetails?.personalDetails?.expected_enrolment_date || "-",
    },
    {
      label: "Grade Applying For",
      value: data?.StudentDetails?.personalDetails?.grade_applying_for || "-",
    },
    {
      label: "School Address",
      value: data?.StudentDetails?.personalDetails?.school_address || "-",
    },
    {
      label: "School Contact Phone",
      value:
        `${data?.StudentDetails?.personalDetails?.school_countryCode} - ${data?.StudentDetails?.personalDetails?.school_contact}` ||
        "-",
    },
    {
      label: "School Contact Email",
      value: data?.StudentDetails?.personalDetails?.school_email || "-",
    },
    {
      label: "Teacher's Name",
      value: data?.StudentDetails?.personalDetails?.teacher_name || "-",
    },
    {
      label: "Teacher's Email",
      value: data?.StudentDetails?.personalDetails?.teacher_email || "-",
    },
    {
      label: "Residential Address",
      value: data?.StudentDetails?.personalDetails?.residential_address || "-",
    },
    {
      label: "Expected Enrolment Date",
      value:
        data?.StudentDetails?.personalDetails?.expected_enrolment_date || "-",
    },
    {
      label: "Grade Applying For",
      value: data?.StudentDetails?.personalDetails?.grade_applying_for || "-",
    },
    {
      label:
        "Has the applicant lived in the above-mentioned address continuously for the past five years?",
      value:
        data?.StudentDetails?.personalDetails?.Is_lived_in_pastfiveyears || "-",
    },
  ];
  const StripedListItem = styled(ListItem)(({ theme, index }) => ({
    backgroundColor:
      index % 2 === 0 ? theme.palette.action.hover : "transparent",
    padding: "20px",
    display: "flex",
    alignContent: "center",

    gap: "10px",
  }));

  const generateKey = (key) => {
    if (key === "avg_month_sal") {
      return "Average Monthly Salary";
    } else if (key === "high_edu_level") {
      return "Highest Education Level";
    } else if (key === "Is_company_US") {
      return "Is the company in the United States?";
    } else if (key === "Is_taking_any_medication") {
      return "Is the applicant taking any medication?";
    } else if (key === "Is_taking_any_medication_des") {
      return "Description of medication";
    } else if (key === "can_use_toilet_independently") {
      return "Can the applicant use his/her own toilet independently?";
    } else if (key === "can_use_toilet_independently_des") {
      return "Can the applicant use his/her own toilet independently Description?";
    } else if (key === "can_wear_his_clothes") {
      return "Can the applicant wear his/her own clothes";
    } else if (key === "can_wear_his_clothes_des") {
      return "Can the applicant wear his/her own clothes Description?";
    } else if (key === "difficulty_In_physical_skills") {
      return "Does the applicant have any difficulties with coordination, balance, or physical skills";
    } else if (key === "difficulty_In_physical_skills_des") {
      return "Does the applicant have any difficulties with coordination, balance, or physical skills Description";
    } else if (key === "had_trouble_paying_attention") {
      return "Has the applicant ever had trouble paying attention/concentration?";
    } else if (key === "had_trouble_paying_attention_des") {
      return "Has the applicant ever had trouble paying attention/concentration Description?";
    } else if (key === "have_allergies") {
      return "Does the applicant have allergies?";
    } else if (key === "have_allergies_des") {
      return "Does the applicant have allergies Description?";
    } else if (key === "have_serious_hlth_prob") {
      return "Does the applicant have serious health problems?";
    } else if (key === "have_serious_hlth_prob_des") {
      return "Does the applicant have serious health problems Description?";
    } else if (key === "attended_special_classes") {
      return "Have you ever attended special education classes or had issues related to physical or mental disabilities?";
    } else if (key === "fav_thing_todo_aft_cl") {
      return "What is your favorite thing to do after class?";
    } else if (key === "hav_social_dev_prob") {
      return " Do you have any social development problems?";
    } else if (key === "like_most_in_shl") {
      return "What do you like to do most when you are in school?";
    } else if (key === "like_reading") {
      return "Do you like reading?";
    } else if (key === "sought_additional_support") {
      return "Have you ever sought additional support from the school (such as English supplementary courses, psychological counseling)?";
    } else if (key === "father_fav_hobby") {
      return "Father Favorite Hobby";
    } else if (key === "father_intersting_thing") {
      return " Is there anything else interesting Applicant Father had like us to know?";
    } else if (key === "mother_fav_hobby") {
      return "Mother Favorite Hobby";
    } else if (key === "mother_intersting_thing") {
      return " Is there anything else interesting Applicant Mother had like us to know?";
    } else if (key === "have_physical_disability") {
      return " Does the applicant have a physical disability?";
    } else if (key === "have_physical_disability_des") {
      return " Does the applicant have a physical disability Description?";
    } else if (key === "fav_thing_todo_aft_cls") {
      return "What is your favorite thing to do after class?";
    }

    return key.replace(/_/g, " ");
  };
  return (
    <Admin>
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Personal details
        </RobotoTypo>
        <Stack
          gap="12px"
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "16px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          <List>
            {personalDetails.map((item, index) => (
              <StripedListItem index={index} key={item.label}>
                <RobotoTypo
                  sx={{ fontWeight: "500", color: "#000000", fontSize: "16px" }}
                >
                  {item.label} :
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
                >
                  {item.value}
                </RobotoTypo>
              </StripedListItem>
            ))}
          </List>
        </Stack>
      </Stack>
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Language Survey
        </RobotoTypo>
        <Stack
          gap="12px"
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          <RobotoTypo>1. Primary Languages</RobotoTypo>
          <Box>
            {data?.StudentDetails?.personalDetails?.primary_languages?.map(
              (data) => (
                <Box
                  display="flex"
                  width={"100%"}
                  justifyContent={"space-between"}
                >
                  <Stack gap="12px">
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#000000",
                        fontSize: "16px",
                      }}
                    >
                      Langauge
                    </RobotoTypo>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#177D84",
                        fontSize: "16px",
                      }}
                    >
                      {data?.name}
                    </RobotoTypo>
                  </Stack>
                  <Stack gap="12px">
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#000000",
                        fontSize: "16px",
                      }}
                    >
                      Level
                    </RobotoTypo>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#177D84",
                        fontSize: "16px",
                      }}
                    >
                      {data?.level}
                    </RobotoTypo>
                  </Stack>
                </Box>
              )
            )}
          </Box>
          <RobotoTypo>
            2. Please fill in the following blanks with four levels: fluent,
            intermediate, beginner, and undeveloped:
          </RobotoTypo>
          <Box>
            {data?.StudentDetails?.personalDetails?.language_levels?.map(
              (data) => (
                <Box
                  display="flex"
                  width={"100%"}
                  justifyContent={"space-between"}
                >
                  <Stack width="100%" gap="12px">
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#000000",
                        fontSize: "16px",
                      }}
                    >
                      {data?.name}
                    </RobotoTypo>
                    <Box
                      display={"flex"}
                      width={"100%"}
                      justifyContent={"space-between"}
                    >
                      <Stack gap="12px">
                        <RobotoTypo
                          sx={{
                            fontWeight: "500",
                            color: "#000000",
                            fontSize: "16px",
                          }}
                        >
                          Speaking
                        </RobotoTypo>
                        <RobotoTypo
                          sx={{
                            fontWeight: "500",
                            color: "#177D84",
                            fontSize: "16px",
                          }}
                        >
                          {data?.speaking || "-"}
                        </RobotoTypo>
                      </Stack>
                      <Stack gap="12px">
                        <RobotoTypo
                          sx={{
                            fontWeight: "500",
                            color: "#000000",
                            fontSize: "16px",
                          }}
                        >
                          Reading
                        </RobotoTypo>
                        <RobotoTypo
                          sx={{
                            fontWeight: "500",
                            color: "#177D84",
                            fontSize: "16px",
                          }}
                        >
                          {data?.reading || "-"}
                        </RobotoTypo>
                      </Stack>
                      <Stack gap="12px">
                        <RobotoTypo
                          sx={{
                            fontWeight: "500",
                            color: "#000000",
                            fontSize: "16px",
                          }}
                        >
                          Writing
                        </RobotoTypo>
                        <RobotoTypo
                          sx={{
                            fontWeight: "500",
                            color: "#177D84",
                            fontSize: "16px",
                          }}
                        >
                          {data?.writing || "-"}
                        </RobotoTypo>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              )
            )}
          </Box>
          <RobotoTypo>3. How many years have you learned English?</RobotoTypo>
          <RobotoTypo
            sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
          >
            {data?.years_learned_english || "-"}
          </RobotoTypo>
          <RobotoTypo>
            4. Please select what kind of person you think the applicant is
          </RobotoTypo>

          <Grid xs={12}>
            <RobotoTypo
              mb={2}
              sx={{
                fontWeight: "500",
                color: "#003B5C",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              Ordinary
            </RobotoTypo>
            {data?.StudentDetails?.personalDetails?.kind_of_person?.map(
              (itm, index) => (
                <Stack
                  mb={2}
                  columnGap={5}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <RobotoTypo
                    sx={{
                      fontWeight: "500",
                      color: "#003B5C",
                      fontSize: "18px",
                      minWidth: "140px",
                      textAlign: "center",
                    }}
                  >
                    {itm?.name1}
                  </RobotoTypo>
                  <Slider
                    value={itm?.value}
                    name="value"
                    sx={{ ...customSlider }}
                    step={10}
                  />
                  <RobotoTypo
                    sx={{
                      fontWeight: "500",
                      color: "#003B5C",
                      fontSize: "18px",
                      minWidth: "140px",
                      textAlign: "center",
                    }}
                  >
                    {itm?.name2}
                  </RobotoTypo>
                </Stack>
              )
            )}
          </Grid>
        </Stack>
      </Stack>
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          The following is information related to the applicant’s father
        </RobotoTypo>
        <Stack
          gap="12px"
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          {Object.entries(
            data.StudentDetails?.familyDetails?.fatherDetails
          ).map(([key, value], index) => (
            <StripedListItem index={index} key={key}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                {generateKey(key)} :
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {value}
              </RobotoTypo>
            </StripedListItem>
          ))}
        </Stack>
      </Stack>

      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          The following is information related to the applicant’s mother
        </RobotoTypo>
        <Stack
          gap="12px"
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          {Object.entries(
            data.StudentDetails?.familyDetails?.motherDetails
          )?.map(([key, value], index) => (
            <StripedListItem index={index} key={key}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                {generateKey(key)} :
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {value}
              </RobotoTypo>
            </StripedListItem>
          ))}
        </Stack>
      </Stack>

      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Applicant’s Grandmother’s Information
        </RobotoTypo>
        <Stack
          gap="12px"
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          {Object.entries(
            data.StudentDetails?.familyDetails?.grandmotherInfo
          )?.map(([key, value], index) => (
            <StripedListItem index={index} key={key}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                {generateKey(key)} :
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {value}
              </RobotoTypo>
            </StripedListItem>
          ))}
        </Stack>
      </Stack>
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Applicant’s Siblings’ Information
        </RobotoTypo>
        <Stack
          gap="12px"
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          {data.StudentDetails?.familyDetails?.SiblingsInfo?.map(
            (value, index) => (
              <Stack gap="12px">
                {index > 0 && (
                  <Divider sx={{ height: "2px", background: "#D3D3D3" }} />
                )}
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#000000",
                    fontSize: "16px",
                    padding: "20px",
                    textTransform: "capitalize",
                  }}
                >
                  Sibiling {index + 1}
                </RobotoTypo>

                {Object.entries(value)?.map(([key, value], index) => (
                  <StripedListItem index={index} key={key}>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#000000",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      {generateKey(key)} :
                    </RobotoTypo>
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#177D84",
                        fontSize: "16px",
                      }}
                    >
                      {value}
                    </RobotoTypo>
                  </StripedListItem>
                ))}
              </Stack>
            )
          )}
        </Stack>
      </Stack>
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Development and health history details:
        </RobotoTypo>
        {data.StudentDetails?.otherDetails?.health_history_Details && (
          <Stack
            gap="12px"
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "24px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              marginBottom: "16px",
            }}
          >
            {Object.entries(
              data.StudentDetails?.otherDetails?.health_history_Details
            )?.map(([key, value], index) => (
              <StripedListItem index={index} key={key}>
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#000000",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  {generateKey(key)} :
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
                >
                  {value}
                </RobotoTypo>
              </StripedListItem>
            ))}
          </Stack>
        )}
      </Stack>
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Other Questions
        </RobotoTypo>
        <Stack
          gap="12px"
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          {Object.entries(
            data?.StudentDetails?.otherDetails?.otherQuestions
          )?.map(([key, value], index) => (
            <StripedListItem index={index} key={key}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                {generateKey(key)} :
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {value}
              </RobotoTypo>
            </StripedListItem>
          ))}
        </Stack>
      </Stack>
      <Stack gap="12px">
        <RobotoTypo
          sx={{
            fontWeight: "600",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
            fontStyle: "italic",
          }}
        >
          Applicants to the American School in Singapore
        </RobotoTypo>
        <Stack
          gap="12px"
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            marginBottom: "16px",
          }}
        >
          {Object.entries(
            data.StudentDetails?.otherDetails?.american_school_sg
          )?.map(([key, value], index) => (
            <StripedListItem index={index} key={key}>
              <RobotoTypo
                sx={{
                  fontWeight: "500",
                  color: "#000000",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
              >
                {generateKey(key)} :
              </RobotoTypo>
              <RobotoTypo
                sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
              >
                {value}
              </RobotoTypo>
            </StripedListItem>
          ))}
        </Stack>
      </Stack>
      <Stack gap="12px">
        <Stack gap="12px">
          <RobotoTypo
            sx={{
              fontWeight: "600",
              color: "#003B5C",
              fontSize: "20px",
              textAlign: "left",
              fontStyle: "italic",
            }}
          >
            Applicants to the American School in Singapore
          </RobotoTypo>
          <Stack
            gap="12px"
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "24px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              marginBottom: "16px",
            }}
          >
            {Object.entries(
              data.StudentDetails?.otherDetails?.american_school_sg
            )?.map(([key, value], index) => (
              <StripedListItem index={index} key={key}>
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#000000",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  {generateKey(key)} :
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
                >
                  {value}
                </RobotoTypo>
              </StripedListItem>
            ))}
          </Stack>
        </Stack>
        {!data?.StudentDetails?.payment_details ? (
          <Stack gap="12px">
            <RobotoTypo
              sx={{
                fontWeight: "600",
                color: "#003B5C",
                fontSize: "20px",
                textAlign: "left",
                fontStyle: "italic",
              }}
            >
              Payment Details
            </RobotoTypo>
            <Stack
              gap="12px"
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                padding: "24px",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                marginBottom: "16px",
              }}
            >
              <StripedListItem>
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#000000",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  Applicatant has been choosed Pay later (Application_fees) :
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
                >
                  S$ {data?.StudentDetails?.application_fee}
                </RobotoTypo>
              </StripedListItem>
            </Stack>
          </Stack>
        ) : (
          <Stack gap="12px">
            <RobotoTypo
              sx={{
                fontWeight: "600",
                color: "#003B5C",
                fontSize: "20px",
                textAlign: "left",
                fontStyle: "italic",
              }}
            >
              Payment Details
            </RobotoTypo>
            <Stack
              gap="12px"
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                padding: "24px",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                marginBottom: "16px",
              }}
            >
              <StripedListItem
                sx={{
                  backgroundColor: theme.palette.action.hover,
                }}
              >
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#000000",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  Paid Applicant Name :
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
                >
                  {data?.StudentDetails?.payment_details?.card?.name}
                </RobotoTypo>
              </StripedListItem>
              <StripedListItem>
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#000000",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  Paid Amount :
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
                >
                  S$ {data?.StudentDetails?.payment_details?.amount / 100}
                </RobotoTypo>
              </StripedListItem>
              <StripedListItem
                sx={{
                  backgroundColor: theme.palette.action.hover,
                }}
              >
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#000000",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  Payment Status:
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
                >
                  {data?.StudentDetails?.payment_details?.status}
                </RobotoTypo>
              </StripedListItem>
              <StripedListItem>
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#000000",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  Bank Details:
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
                >
                  {data?.StudentDetails?.payment_details?.card?.bank}
                </RobotoTypo>
              </StripedListItem>
              <StripedListItem
                sx={{
                  backgroundColor: theme.palette.action.hover,
                }}
              >
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#000000",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  Card Type:
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
                >
                  {data?.StudentDetails?.payment_details?.card?.brand}
                </RobotoTypo>
              </StripedListItem>
              <StripedListItem>
                <RobotoTypo
                  sx={{
                    fontWeight: "500",
                    color: "#000000",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  Paid Time:
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "500", color: "#177D84", fontSize: "16px" }}
                >
                  {moment(data?.StudentDetails?.payment?.card?.paid_at).format(
                    "DD MMM YYYY, HH:mm A"
                  )}
                </RobotoTypo>
              </StripedListItem>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Admin>
  );
};

export default SchoolDetails;
