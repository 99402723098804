import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { RobotoTypo } from "../../utils/Typographies";
import axios from "axios";
import { useParams } from "react-router-dom";

function Profile() {
  const userData = localStorage.getItem("student_data")
    ? JSON.parse(localStorage.getItem("student_data"))
    : "";

  const [studentData, setStudentData] = useState({});

  const fetchStudentData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/student-credentials/${userData._id}`
      )
      .then((response) => {
        console.log(response.data);
        setStudentData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (userData._id) {
      fetchStudentData();
    }
  }, [userData._id]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 2, sm: 10 },
        }}
      >
        <Box
          sx={{
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
            width: { xs: "80%", sm: "40%" },
            p: 2,
          }}
        >
          <RobotoTypo sx={{ fontSize: "1rem", color: "#414141" }}>
            Name
          </RobotoTypo>
          <RobotoTypo sx={{ fontSize: "1.1rem", fontWeight: 700 }}>
            {studentData?.name}
          </RobotoTypo>
        </Box>

        <Box
          sx={{
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
            width: { xs: "80%", sm: "40%" },
            p: 2,
          }}
        >
          <RobotoTypo sx={{ fontSize: "1rem", color: "#414141" }}>
            Email
          </RobotoTypo>
          <RobotoTypo sx={{ fontSize: "1.1rem", fontWeight: 700 }}>
            {studentData?.email}
          </RobotoTypo>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          mt: 3,
          gap: { xs: 2, sm: 10 },
        }}
      >
        <Box
          sx={{
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
            width: { xs: "80%", sm: "40%" },
            p: 2,
          }}
        >
          <RobotoTypo sx={{ fontSize: "1rem", color: "#414141" }}>
            Phone
          </RobotoTypo>
          <RobotoTypo
            sx={{ fontSize: "1.1rem", fontWeight: 700 }}
          >{`${studentData?.country_code} ${studentData?.phone}`}</RobotoTypo>
        </Box>

        <Box
          sx={{
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
            width: { xs: "80%", sm: "40%" },
            p: 2,
          }}
        >
          <RobotoTypo sx={{ fontSize: "1rem", color: "#414141" }}>
            Contact
          </RobotoTypo>
          <RobotoTypo sx={{ fontSize: "1.1rem", fontWeight: 700 }}>
            {studentData?.contact_number}
          </RobotoTypo>
        </Box>
      </Box>
    </Box>
  );
}

export default Profile;
