import React from "react";
import { Box } from "@mui/material";
import { RobotoTypo, TimesNewTypo } from "../../../utils/Typographies";
import { useTranslation } from "react-i18next";
import { ReactComponent as Circle } from "../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg";

export default function CertificationCards() {
  const { t } = useTranslation();
  return (
    <Box>
      <Box
        sx={{
          borderBottomRightRadius: {
            xs: "0",
            md: "0",
            lg: "0",
            xl: "50px",
            xxl: "50px",
          },
          // padding: { xs: '0', sm: "0", md: '0', lg: "40px", xl: '40px' },
          padding: "40px 0",
          zIndex: 10000,
          height: { xs: "11%", sm: "11%", md: "11%", lg: "15%", xl: "15%" },
          width: { xs: "100%", sm: "100%", md: "100%", lg: "100%", xl: "100%" },

          background: "#F5DFBA",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* {t('aboutUs.why_dr', { returnObjects: true }).map((item, index) => ( */}
        <Box
          // key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <RobotoTypo
            sx={{
              fontWeight: "700",
              color: "#003B5C",
              fontSize: { xs: "22px", sm: "25px", md: "28px", lg: "30px" },
              textAlign: "center",
            }}
          >
            {
              t(
                "service.studentPassApplication.studentApplicationProcessBullets",
                { returnObjects: true }
              ).title1
            }{" "}
          </RobotoTypo>
          <TimesNewTypo
            sx={{
              fontWeight: "700",
              color: "#177D84",
              fontSize: { xs: "18px", md: "22px", lg: "24px" },
              textAlign: "center",
            }}
          >
            {
              t(
                "service.studentPassApplication.studentApplicationProcessBullets",
                { returnObjects: true }
              ).title2
            }
          </TimesNewTypo>
        </Box>
        {/* ))} */}
      </Box>
      <Box
        sx={{
          background: "#F7F7F7",
          zIndex: "10000",
          display: "flex",
          justifyContent: "center",
          padding: { xs: "12% 0% 8% 0%", md: "8% 0% 5% 0%" },
          gap: "50px",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            margin: { xs: "0px 20px", sm: "0px" },
            width: "80%",
            maxWidth: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "3px solid #177D84",
            borderRadius: "25px",
            position: "relative",
            backgroundColor: "white",
          }}
        >
          <Box sx={{ position: "absolute", top: "-40px" }}>
            <Box
              sx={{
                display: "flex",
                position: "relative",
                alignItems: "center",
                background: "white",
                justifyContent: "center",
                border: "2px solid #177D84",
                width: "80px",
                height: "80px",
                borderRadius: "50px",
              }}
            >
              <Circle style={{ position: "absolute", left: -4 }} />
              <RobotoTypo
                sx={{
                  color: "#003B5C",
                  fontWeight: "700",
                  letterSpacing: "0.02em",
                  lineHeight: "38px",
                  fontSize: { xs: "20px", sm: "24px", md: "32px" },
                }}
              >
                01
              </RobotoTypo>
            </Box>
          </Box>

          <Box
            sx={{
              textAlign: "center",
              padding: { xs: "48px 40px", sm: "48px 40px" },
            }}
          >
            <RobotoTypo
              sx={{
                color: "#003B5C",
                fontWeight: "700",
                letterSpacing: "0.02em",
                lineHeight: "38px",
                fontSize: { xs: "18px", sm: "18px", md: "20px" },
              }}
            >
              {
                t(
                  "service.QualificationCertificate.QualificationCertificateIntro",
                  { returnObjects: true }
                ).title1
              }
            </RobotoTypo>
            <RobotoTypo
              sx={{
                color: "#177D84",
                fontWeight: "500",
                letterSpacing: "0.02em",
                lineHeight: "24px",
                fontSize: { xs: "18px", sm: "18px", md: "18 px" },
              }}
            >
              {
                t(
                  "service.QualificationCertificate.QualificationCertificateIntro",
                  { returnObjects: true }
                ).subTitle1
              }
            </RobotoTypo>
            <br />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <RobotoTypo
                sx={{
                  color: "#000000",
                  fontWeight: "400",
                  letterSpacing: "0.02em",
                  lineHeight: "27px",
                  fontSize: { xs: "15px", md: "16px" },
                }}
              >
                {
                  t(
                    "service.QualificationCertificate.QualificationCertificateIntro",
                    { returnObjects: true }
                  ).description1
                }
              </RobotoTypo>
              <RobotoTypo
                sx={{
                  color: "#000000",
                  fontWeight: "400",
                  letterSpacing: "0.02em",
                  lineHeight: "27px",
                  fontSize: { xs: "15px", md: "16px" },
                }}
              >
                {
                  t(
                    "service.QualificationCertificate.QualificationCertificateIntro",
                    { returnObjects: true }
                  ).description2
                }
              </RobotoTypo>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            margin: { xs: "0 20px", sm: "0px" },
            width: "80%",
            maxWidth: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "3px solid #177D84",
            borderRadius: "25px",
            position: "relative",
            backgroundColor: "white",
          }}
        >
          <Box sx={{ position: "absolute", top: "-40px" }}>
            <Box
              sx={{
                display: "flex",
                position: "relative",
                alignItems: "center",
                background: "white",
                justifyContent: "center",
                border: "2px solid #177D84",
                width: "80px",
                height: "80px",
                borderRadius: "50px",
              }}
            >
              <Circle style={{ position: "absolute", left: -4 }} />
              <RobotoTypo
                sx={{
                  color: "#003B5C",
                  fontWeight: "700",
                  letterSpacing: "0.02em",
                  lineHeight: "38px",
                  fontSize: { xs: "20px", sm: "24px", md: "32px" },
                }}
              >
                02
              </RobotoTypo>
            </Box>
          </Box>

          <Box
            sx={{
              textAlign: "center",
              padding: { xs: "48px 40px", sm: "48px 50px" },
            }}
          >
            <RobotoTypo
              sx={{
                color: "#003B5C",
                fontWeight: "700",
                letterSpacing: "0.02em",
                lineHeight: "38px",
                fontSize: { xs: "18px", sm: "18px", md: "20px" },
              }}
            >
              {
                t(
                  "service.QualificationCertificate.QualificationCertificateIntro",
                  { returnObjects: true }
                ).title2
              }
            </RobotoTypo>
            <RobotoTypo
              sx={{
                color: "#177D84",
                fontWeight: "500",
                letterSpacing: "0.02em",
                lineHeight: "24px",
                fontSize: { xs: "18px", sm: "18px", md: "18px" },
              }}
            >
              {
                t(
                  "service.QualificationCertificate.QualificationCertificateIntro",
                  { returnObjects: true }
                ).subTitle2
              }
            </RobotoTypo>
            <br />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <RobotoTypo
                sx={{
                  color: "#000000",
                  fontWeight: "400",
                  letterSpacing: "0.02em",
                  lineHeight: "27px",
                  fontSize: { xs: "15px", md: "16px" },
                }}
              >
                {
                  t(
                    "service.QualificationCertificate.QualificationCertificateIntro",
                    { returnObjects: true }
                  ).description3
                }
              </RobotoTypo>
              <RobotoTypo
                sx={{
                  color: "#000000",
                  fontWeight: "400",
                  letterSpacing: "0.02em",
                  lineHeight: "27px",
                  fontSize: { xs: "15px", md: "16px" },
                }}
              >
                {
                  t(
                    "service.QualificationCertificate.QualificationCertificateIntro",
                    { returnObjects: true }
                  ).description4
                }
              </RobotoTypo>
              <RobotoTypo
                sx={{
                  color: "#000000",
                  fontWeight: "400",
                  letterSpacing: "0.02em",
                  lineHeight: "27px",
                  fontSize: { xs: "15px", md: "16px" },
                }}
              >
                {
                  t(
                    "service.QualificationCertificate.QualificationCertificateIntro",
                    { returnObjects: true }
                  ).description5
                }
              </RobotoTypo>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
