import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import Admin from "../Dashboard";
import { RobotoTypo } from "../../../utils/Typographies";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import DeleteSchoolModal from "./DeleteSchoolModal";

const rows = [
  { id: 1, title: "Title 1", description: "Description 1" },
  { id: 2, title: "Title 2", description: "Description 2" },
  { id: 3, title: "Title 3", description: "Description 3" },
  // Add more rows as needed
];

export default function SchoolList() {
  const navigate = useNavigate();
  const [schoolList, setSchoolList] = useState([]);
  const location = useLocation();
  const schoolType = location?.pathname.replace("/admin/", "");

  const getSchoolByType = () => {
    const obj = {
      primarySchool: "getPrimarySchools",
      secondarySchool: "getSecondarySchools",
      internationalSchool: "getInternationalSchools",
      university: "getUniversities",
      languageCourse: "getLanguageCourse",
      kindergarden: "getKindergarden",
    };
    return obj[schoolType];
  };

  const getSchools = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/${getSchoolByType()}`
      );
      console.log("response get ", response);
      if (response.status == 200) {
        if (Array.isArray(response?.data)) {
          setSchoolList(response?.data != "" ? response?.data : []);
        } else if (
          typeof response?.data === "object" &&
          response?.data !== null
        ) {
          setSchoolList(response?.data != "" ? [response?.data] : []);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    setSchoolList([]);
    getSchools();
  }, [schoolType]);
  const schoolOptions = [
    { value: "primarySchool", label: "Primary School" },
    { value: "secondarySchool", label: "Secondary School" },
    { value: "internationalSchool", label: "International School" },
    { value: "university", label: "University" },
    { value: "languageCourse", label: "Language Course" },
    { value: "kindergarten", label: "ChildCare & Kindergarten" },
  ];
  const LoggedInEmail = localStorage.getItem("admin_email");
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/deleteSchoolById/${id}/${LoggedInEmail}`,
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 200) {
        getSchools();
      }
    } catch (error) {
      if (error.response.status == 403) {
        navigate("/admin/login");
      }
    }
  };
  const handleEdit = (id) => {
    navigate(`/admin/${schoolType}/` + id);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [deleteSchool, setDeleteSchool] = useState("");
  const [deleteSchoolId, setDeleteSchoolId] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    setSelectedValue(value);
    setAnchorEl(null);
  };
  const displaySchoolTypes = (school) => {
    const labels = school
      .map((value) => {
        const option = schoolOptions.find((option) => option.value === value);
        return option ? option.label : null;
      })
      .filter((label) => label);

    let output;
    if (labels.length > 3) {
      output = labels.slice(0, 3).join(", ") + " ...";
    } else {
      output = labels.join(", ");
    }
    return output;
  };
  const handleDeleteSchool = (school, schoolId) => {
    setDeleteSchool(school);
    setDeleteSchoolId(schoolId);
    setOpen(true);
  };
  const handleSwitchChange = async (schoolId, currentValue) => {
    console.log("changing");
    // Toggle the value
    const newValue = !currentValue;

    try {
      // Call your API to update the school visibility
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/updateSchoolStatusById`,
        {
          id: schoolId,
          status: newValue,
        }
      );
      console.log("API response:", response.data);
      getSchools();
      // Optionally, update the local state here if necessary
    } catch (error) {
      console.error("Error updating school visibility:", error);
    }
  };
  return (
    <Admin>
      <DeleteSchoolModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        school={deleteSchool}
        handleDelete={handleDelete}
        deleteSchoolId={deleteSchoolId}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mb: 1,
        }}
      >
        <Button
          component="span"
          onClick={() => navigate(`/admin/${schoolType}/add`)}
          variant="contained"
          sx={{
            alignSelf: "center",
            mt: 1,
            width: "fit-content",
            backgroundColor: "#177D84",
            "&:hover": { backgroundColor: "#177D84" },
          }}
        >
          Add
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sno</TableCell>
              <TableCell>School Name</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell></TableCell>
              <TableCell>Alloted School</TableCell>
              <TableCell>Active Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schoolList?.length != 0 &&
              schoolList?.map((school, index) => (
                <TableRow key={school.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{school.en.school_name}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        handleDeleteSchool(school.en.school_name, school._id)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEdit(school._id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {displaySchoolTypes(school?.en?.school_types)}
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={school?.isShow}
                          onChange={(event) => {
                            console.log(
                              "Switch changed:",
                              school._id,
                              school.isShow
                            );
                            handleSwitchChange(school._id, school.isShow);
                          }}
                          name="mySwitch"
                          color="primary"
                        />
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Admin>
  );
}
