import React from "react";
import { RobotoTypo } from "../../../utils/Typographies";
import {
  Box,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import LaunchIcon from "@mui/icons-material/Launch";

const PaymentDetails = ({
  paymentData,
  setPaymentData,
  payLater,
  payNow,
  total,
}) => {
  const { t } = useTranslation();
  const handleCheckboxChange = (index) => {
    const newPaymentData = paymentData.map((row, i) => {
      if (row?.id === index) {
        return { ...row, isChecked: !row.isChecked };
      }
      return row;
    });
    setPaymentData(newPaymentData);
  };

  // Calculate total based on checked items

  return total === 0 ? (
    <Box>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "bold",
          color: "#ff5722", // A nice accent color
          padding: "20px",
          border: "1px solid #ff5722", // Match the color
          borderRadius: "8px",
          backgroundColor: "#fff3e0", // Light background for emphasis
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow
          my: "24px",
          mx: "24px", // Space around the text
        }}
      >
        We're sorry, but we are unable to process the payment for this
        application. Please try again later.
      </Typography>
    </Box>
  ) : (
    <>
      <Stack
        sx={{
          padding: "18px 30px",
          backgroundColor: "#e1e1e1",
          mb: "30px",
        }}
      >
        <RobotoTypo
          sx={{
            fontWeight: "500",
            color: "#003B5C",
            fontSize: "20px",
            textAlign: "left",
          }}
        >
          {t("Services_Fee")}
        </RobotoTypo>
      </Stack>
      <Stack px={"40px"} gap="20px">
        <Box>
          {/* Table for rows with isReadable = true */}
          <Table
            style={{
              borderCollapse: "separate",
              width: "100%",
              backgroundColor: "#F1FEFF",
              color: "#177D84",
              border: "2px solid #68C4CA",
              borderRadius: "8px",
              marginBottom: 4,
              paddingInline: "20px",
            }}
          >
            <TableBody>
              {paymentData
                .filter((row) => row?.isReadable)
                .map((row, index) => (
                  <TableRow key={`readable-${index}`}>
                    <TableCell
                      style={{
                        borderBottom: "1px solid #D7EAEB",
                        textAlign: "left",
                        // Add horizontal border
                      }}
                    >
                      <Checkbox
                        checked={row.isChecked}
                        disabled={row.isReadable}
                        sx={{
                          "&.Mui-checked": {
                            color: "#A4D0D3", // Add this line to set the checked color
                          },
                        }}
                        onChange={() => handleCheckboxChange(row?.id)}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "1px solid #D7EAEB",
                        textAlign: "left", // Add horizontal border
                      }}
                    >
                      {row?.url ? (
                        <a
                          href={row?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "Roboto, sans-serif",
                            color: "#177D84",
                            textDecoration: "none",
                            fontWeight: "400",
                            fontSize: "16px",
                          }}
                        >
                          <LaunchIcon
                            style={{ marginRight: 8, fontSize: 18 }}
                          />{" "}
                          {row.label}
                        </a>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: "16px",
                            color: "#177D84",
                            fontWeight: "400",
                          }}
                        >
                          {row.label}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "1px solid #D7EAEB",
                        textAlign: "left", // Add horizontal border
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", color: "#177D84" }}>
                        S$ {isNaN(row.charge) ? 0 : row.charge} /-
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>

        {/* Table for rows with isReadable = false */}
        <Box>
          <Table
            sx={{
              backgroundColor: "#FFFFFF", // Default background for non-readable rows
              color: "#242424",
              border: "2px solid #BABABA",
              borderRadius: "8px",

              borderCollapse: "separate",

              paddingInline: "20px",
            }}
          >
            <TableBody>
              {paymentData
                .filter((row) => !row?.isReadable)
                .map((row, index) => (
                  <TableRow key={`non-readable-${index}`}>
                    <TableCell
                      style={{
                        borderBottom: "1px solid #D7EAEB",
                        textAlign: "left", // Add horizontal border
                      }}
                    >
                      <Checkbox
                        checked={row.isChecked}
                        disabled={row.isReadable}
                        sx={{
                          "&.Mui-checked": {
                            color: "#242424", // Add this line to set the checked color
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#242424", // Border color when not checked
                          },
                          "&.Mui-checked .MuiSvgIcon-root": {
                            color: "#242424", // Border color when checked
                          },
                        }}
                        onChange={() => handleCheckboxChange(row?.id)}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "1px solid #D7EAEB",
                        textAlign: "left", // Add horizontal border
                      }}
                    >
                      {row?.url ? (
                        <a
                          href={row?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "Roboto, sans-serif",
                            color: "#242424",
                            textDecoration: "none",
                            fontWeight: "400",
                            fontSize: "16px",
                          }}
                        >
                          <LaunchIcon
                            style={{ marginRight: 8, fontSize: 18 }}
                          />{" "}
                          {row.label}
                        </a>
                      ) : (
                        <Typography sx={{ fontSize: "16px" }}>
                          {row.label}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "1px solid #D7EAEB",
                        textAlign: "left", // Add horizontal border
                      }}
                    >
                      <Typography sx={{ fontSize: "16px" }}>
                        S$ {isNaN(row.charge) ? 0 : row.charge}/-
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        <Box display="flex" justifyContent={"space-between"}>
          <RobotoTypo
            sx={{ fontWeight: "700", color: "#177D84", fontSize: "20px" }}
          >
            Total Amount
          </RobotoTypo>
          <RobotoTypo
            sx={{ fontWeight: "700", color: "#177D84", fontSize: "20px" }}
          >
            S$ {total} /-
          </RobotoTypo>
        </Box>
      </Stack>
    </>
  );
};

export default PaymentDetails;
