import { Box, Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EnrollmentButtons = () => {
  const { t } = useTranslation();
  const [buttonData, setButtonData] = useState([]);
  const [data, setData] = useState([]);
  const language = useSelector((state) => state.language.language);

  const getEnrollmentDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getEnrollment`
      );
      if (response.status == 200) {
        setButtonData(response.data);
        getData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getData = (data) => {
    console.log("language ", language);
    const languageData = data.map((item) => {
      if (language == "en") {
        return item?.en;
      } else if (language == "ch") {
        return item?.ch;
      }
      else {
        return item?.sch || item?.en;
      }
    });
    setData(languageData);
    console.log("changed", languageData);
  };

  useEffect(() => {
    if (buttonData?.length != 0) {
      getData(buttonData);
    }
  }, [language]);
  useEffect(() => {
    getEnrollmentDetails();
  }, []);
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      justifyContent={"center"}
      sx={{ px: "100px" }}
      gap={"30px"}
      flexWrap={"wrap"}
    >
      {data.map((item, index) => (
        <Button
          key={index}
          variant="Standard"
          onClick={() => {
            navigate(item.url);
          }}
        >
          {item.name}
        </Button>
      ))}
    </Box>
  );
};

export default EnrollmentButtons;
