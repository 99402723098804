import React, { useEffect, useState } from "react";
import { Box, Chip, Typography } from "@mui/material";
import { RobotoTypo, TimesNewTypo } from "../../utils/Typographies";
import { GenYo } from "../../utils/Typographies";
import { useTranslation } from "react-i18next";
import {
  singaporeTeam,
  taiwanTeam,
  hongkongTeam,
  ChinaTeam,
  teamCoo,
  teamCeo,
} from "./teamData";
import axios from "axios";
import { useSelector } from "react-redux";
import { ChildCareSharp, Circle } from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Ceo from "../../assets/team/Ceo.png";
import Coo from "../../assets/team/Coo.png";
const TeamHeadDetails = () => {
  const { t } = useTranslation();
  const [higherOfficialData, setHigherOfficialData] = useState([]);
  const [data, setData] = useState([]);
  const language = useSelector((state) => state.language.language);
  const getHeaderOfficialDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getHeaderDetails`
      );
      if (response.status == 200) {
        setHigherOfficialData(response.data);
        getData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getData = (data) => {
    console.log("language ", language);
    const languageData = data.map((item) => {
      if (language == "en") {
        return item.en;
      } else if (language == "ch") {
        return item.ch;
      } else {
        return item?.sch || item?.en;
      }
    });
    setData(languageData);
    console.log("changed", languageData);
  };

  useEffect(() => {
    if (higherOfficialData?.length != 0) {
      getData(higherOfficialData);
    }
  }, [language]);
  useEffect(() => {
    getHeaderOfficialDetails();
  }, []);
  console.log("data", data);
  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      sx={{ width: "100%", mt: 6 }}
      justifyContent="space-between"
      flexWrap="wrap"
      gap="30px"
    >
      {data?.map((team, index) => (
        <>
          <Box
            sx={{
              width: { xs: "80%", md: "40%" },
              borderRadius: "24px",
              background: "#FFFFFF",
              boxShadow: "0px 4px 4px 0px #00000040",
              px: { xs: "30px", md: "40px" },
              py: { xs: "20px", md: "40px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: { xs: "30px", md: "50px" },
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box>
                <RobotoTypo
                  sx={{
                    fontWeight: "600",
                    color: "#177D84",
                    fontSize: { xs: "12px", md: "12px" },
                    border: "1.5px solid #177D84",
                    width: "140px",
                    borderRadius: "50px",
                    py: "2px",
                    textAlign: "center",
                    letterSpacing: "0.03em",
                  }}
                >
                  {team.role}
                </RobotoTypo>
              </Box>
              <Box>
                <Box
                  component="img"
                  src={`${process.env.REACT_APP_FILE_URL}${team.image}`}
                  alt={`Preview ${team.image}`}
                  sx={{
                    width: { xs: "80px", sm: "120px" },
                    height: { xs: "80px", sm: "120px" },
                    borderRadius: "50%",
                  }}
                />
              </Box>
            </Box>
            <RobotoTypo
              sx={{
                fontWeight: "800",
                color: "#003B5C",
                fontSize: { xs: "14px", md: "20px" },
                my: "10px",
              }}
            >
              {team.name}
            </RobotoTypo>
            <List>
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "20px",
                  my: 1,
                }}
              >
                <RobotoTypo
                  sx={{
                    fontWeight: "400",
                    letterSpacing: "0.03em",

                    color: "#242424",
                    fontSize: { xs: "14px", md: "14px", lg: "14px" },

                    textAlign: "left",
                  }}
                >
                  {<p dangerouslySetInnerHTML={{ __html: team.description }} />}
                </RobotoTypo>
              </Box>
            </List>
          </Box>
        </>
      ))}
      {/* <Box>
            <Chip label="CEO" variant="outlined" />
            <Typography>

            </Typography>
          </Box> */}
    </Box>
  );
};

export default TeamHeadDetails;
