import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import { Provider } from "react-redux";
import store from "./redux/store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
const addOmiseScript = () => {
  const scriptId = "omise-script";

  if (!document.getElementById(scriptId)) {
    const script = document.createElement("script");
    script.id = scriptId;
    script.src = "https://cdn.omise.co/omise.js";
    script.async = true;
    script.onload = () => {
      if (window.OmiseCard) {
        window.OmiseCard.configure({
          publicKey: "pkey_test_60fen2ih2w9ofin05qf",
        });
      }
    };
    script.onerror = () => {
      console.error("Failed to load Omise.js");
    };
    document.body.appendChild(script);
  }
};

addOmiseScript(); // Load script before the app renders
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </LocalizationProvider>
);
