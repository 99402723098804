import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import Admin from "../Dashboard";
import { RobotoTypo } from "../../../utils/Typographies";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import DeleteSchoolModal from "../School/DeleteSchoolModal";

export default function NewsList() {
  const navigate = useNavigate();
  const [newsList, setNewsList] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteSchool, setDeleteSchool] = useState("");
  const [deleteSchoolId, setDeleteSchoolId] = useState("");

  console.log("newsList ", newsList);
  const getNews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getNews`
      );
      console.log("response get ", response);
      if (response.status == 200) {
        if (Array.isArray(response?.data)) {
          setNewsList(response?.data != "" ? response?.data : []);
        } else if (
          typeof response?.data === "object" &&
          response?.data !== null
        ) {
          setNewsList(response?.data != "" ? [response?.data] : []);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    setNewsList([]);
    getNews();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/deleteNewsById/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 200) {
        getNews();
      }
    } catch (error) {
      if (error.response.status == 403) {
        navigate("/admin/login");
      }
    }
  };
  const handleEdit = (id) => {
    navigate(`/admin/news/` + id);
  };
  const handleDeleteSchool = (school, schoolId) => {
    setDeleteSchool(school);
    setDeleteSchoolId(schoolId);
    setOpen(true);
  };
  const handleSwitchChange = async (newsId, currentValue) => {
    console.log("changing");
    // Toggle the value
    const newValue = !currentValue;

    try {
      // Call your API to update the school visibility
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/updateNewsStatusById`,
        {
          id: newsId,
          status: newValue,
        },
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      console.log("API response:", response.data);
      getNews();
      // Optionally, update the local state here if necessary
    } catch (error) {
      console.error("Error updating school visibility:", error);
    }
  };
  return (
    <Admin>
      <DeleteSchoolModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        school={deleteSchool}
        handleDelete={handleDelete}
        deleteSchoolId={deleteSchoolId}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mb: 1,
        }}
      >
        <Button
          component="span"
          onClick={() => navigate("/admin/news/add")}
          variant="contained"
          sx={{
            alignSelf: "center",
            mt: 1,
            width: "fit-content",
            backgroundColor: "#177D84",
            "&:hover": { backgroundColor: "#177D84" },
          }}
        >
          Add
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sno</TableCell>
              <TableCell>News Name</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell></TableCell>
              <TableCell>Active Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newsList?.length != 0 &&
              newsList?.map((news, index) => (
                <TableRow key={news._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{news.en.title}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        handleDeleteSchool(news.en.title, news._id)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEdit(news._id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={news?.isShow}
                          onChange={(event) => {
                            console.log(
                              "Switch changed:",
                              news._id,
                              news.isShow
                            );
                            handleSwitchChange(news._id, news.isShow);
                          }}
                          name="mySwitch"
                          color="primary"
                        />
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Admin>
  );
}
