import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import { Clear } from "@mui/icons-material";
import Admin from "../Dashboard";
import { RobotoTypo } from "../../../utils/Typographies";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

export default function Teams() {
  const [images, setImages] = useState([]);
  const [data, setData] = useState({
    personName_en: "",
    role_name: "",
    personName_ch: "",
    personName_sch: "",
  });
  const [dataError, setDataError] = useState({
    personName_en: "",
    role_name: "",
    personName_ch: "",
    personName_sch: "",
  });
  const [file, setFile] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [fileError, setFileError] = useState("");
  let { teamId } = useParams();
  const location = useLocation();
  let teamType = location?.pathname.replace("/admin/", "");
  teamType = teamType.split("/");

  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    const imagesArray = files.map((file) => URL.createObjectURL(file));
    setImages((prevImages) => [...prevImages, ...imagesArray]);
    setImages(imagesArray);
    setFile(e.target.files[0]);
    setImageUrl("");
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setFile([]);
  };

  const validate = () => {
    let newErrors = [],
      errorMessges = {};
    if (data.personName_en == "") {
      setDataError((prev) => ({ ...prev, personName_en: "Name is required" }));
      newErrors.push(false);
    } else {
      setDataError((prev) => ({ ...prev, personName_en: "" }));
      newErrors.push(true);
    }
    if (data.role_name == "") {
      setDataError((prev) => ({ ...prev, role_name: "Role is required" }));
      newErrors.push(false);
    } else {
      setDataError((prev) => ({ ...prev, role_name: "" }));
      newErrors.push(true);
    }
    if (data.personName_ch == "") {
      setDataError((prev) => ({ ...prev, personName_ch: "Name is required" }));
      newErrors.push(false);
    } else {
      setDataError((prev) => ({ ...prev, personName_ch: "" }));
      newErrors.push(true);
    }

    if (imageUrl == "" && file?.length == 0) {
      setDataError((prev) => ({ ...prev, image: "Please upload image" }));
      newErrors.push(false);
    } else {
      setFileError("");
      newErrors.push(true);
    }
    let errorStatus = newErrors.includes(false);
    return !errorStatus;
  };
  console.log("dataerror ", dataError);

  const handleSubmit = () => {
    const validation = validate();
    console.log("va ", validation);
    if (validation) {
      const form = new FormData();
      if (imageUrl == "") {
        console.log("with");
        form.append("image", file);
      } else {
        console.log("else");
        form.append("image", imageUrl);
      }

      form.append("en_personName", data.personName_en);
      form.append("sch_personName", data.personName_sch);
      form.append("role_name", data.role_name);
      form.append("ch_personName", data.personName_ch);
      form.append("team_type", teamType[0]);
      if (teamId) {
        updatePerson(form);
      } else {
        addPerson(form);
      }
    }
  };

  const addPerson = async (reqData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/addPerson`,
        reqData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 201) {
        navigate(`/admin/${teamType[0]}`);
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    }
  };

  const getPersonById = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getPersonById/` + id,
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 200) {
        const personData = response.data;
        console.log("getpersonData ", personData);
        setData({
          personName_en: personData.en.person_name,
          personName_sch: personData?.sch?.person_name ?? "",
          role_name: personData.en.role_name,
          personName_ch: personData.ch.person_name,
        });
        setImageUrl(personData.en.image);
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    }
  };

  const updatePerson = async (reqData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/updatePersonById/` + teamId,
        reqData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 201) {
        navigate(`/admin/${teamType[0]}`);
      }
    } catch (error) {
      if (error.response.status == 403) {
        localStorage.clear();
        navigate("/admin/login");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
    setDataError((prev) => ({ ...prev, [name]: "" }));
  };

  useEffect(() => {
    if (teamId) {
      getPersonById(teamId);
    }
  }, []);

  return (
    <Admin>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 20, sm: 24 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              English
            </RobotoTypo>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Name
            </RobotoTypo>
            <TextField
              name="personName_en"
              value={data.personName_en}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.personName_en != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {dataError.personName_en}
              </RobotoTypo>
            )}
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 20, sm: 24 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Simplified Chinese
            </RobotoTypo>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Name
            </RobotoTypo>
            <TextField
              name="personName_sch"
              value={data.personName_sch}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.personName_sch != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {dataError.personName_sch}
              </RobotoTypo>
            )}
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                my: 1,
              }}
            >
              Role
            </RobotoTypo>
            <TextField
              name="role_name"
              value={data.role_name}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.role_name != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {dataError.role_name}
              </RobotoTypo>
            )}
          </Box>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 24 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Chinese
            </RobotoTypo>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Name
            </RobotoTypo>
            <TextField
              name="personName_ch"
              value={data.personName_ch}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.personName_ch != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {dataError.personName_ch}
              </RobotoTypo>
            )}
          </Box>
        </Box>
        <Box>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 20 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Upload Person Image
          </RobotoTypo>
          <input
            type="file"
            accept="image/*"
            // multiple
            style={{ display: "none" }}
            id="image-input"
            onChange={handleImageChange}
          />
          <label htmlFor="image-input">
            <Button
              component="span"
              variant="contained"
              sx={{
                mb: 2,
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
            >
              Select Image
            </Button>
            {fileError != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {fileError}
              </RobotoTypo>
            )}
          </label>
          <Grid container spacing={2}>
            {imageUrl ? (
              <Grid item xs={6} md={4}>
                <Box style={{ position: "relative" }}>
                  <Box
                    component="img"
                    src={`${process.env.REACT_APP_FILE_URL}${imageUrl}`}
                    alt={`Preview ${imageUrl}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <IconButton
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={() => setImageUrl("")}
                  >
                    <Clear />
                  </IconButton>
                </Box>
              </Grid>
            ) : (
              images?.map((image, index) => (
                <Grid item key={index} xs={6} md={4}>
                  <Box style={{ position: "relative" }}>
                    <Box
                      component="img"
                      src={image}
                      alt={`Preview ${index}`}
                      style={{ width: "100%", height: "auto" }}
                    />
                    <IconButton
                      style={{ position: "absolute", top: 0, right: 0 }}
                      onClick={() => handleRemoveImage(index)}
                    >
                      <Clear />
                    </IconButton>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
        <Button
          component="span"
          onClick={handleSubmit}
          variant="contained"
          sx={{
            alignSelf: "center",
            mt: 1,
            width: "fit-content",
            backgroundColor: "#177D84",
            "&:hover": { backgroundColor: "#177D84" },
          }}
        >
          {teamId ? "Edit" : "Add"}
        </Button>
      </Box>
    </Admin>
  );
}
