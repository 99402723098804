import React, { useEffect, useState } from "react";
import Admin from "../Dashboard";
import { Box, Button, Divider, Stack, TextField } from "@mui/material";
import { Description } from "@mui/icons-material";
import { RobotoTypo } from "../../../utils/Typographies";
import ReactQuill from "react-quill";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AdminTermsCondition = () => {
  const navigate = useNavigate();
  const [termsData, setTermsData] = useState([
    {
      title: "",
      description: "",
    },
  ]);
  const [termsError, setTermsError] = useState({
    title: "",
    description: "",
  });
  const toolbarOptions = [
    ["bold", "italic", "underline", "link"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ color: [] }, { background: [] }],
  ];
  const module = { toolbar: toolbarOptions };
  const handleAdd = () => {
    setTermsData([...termsData, { title: "", description: "" }]);
  };
  const handleDelete = (index) => {
    if (index == 0) {
      return;
    }
    const deletedData = termsData.filter((_, i) => i !== index);
    setTermsData(deletedData);
  };
  const handleDescriptionChange = (index, e) => {
    const updatedData = [...termsData];
    updatedData[index].description = e;
    setTermsData(updatedData);
  };
  const handleChange = (index, e) => {
    const updatedData = [...termsData];
    updatedData[index].title = e.target.value;
    setTermsData(updatedData);
  };

  const getTerms = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getTerms`
      );

      if (response.status === 200) {
        if (response.data.length > 0) {
          const terms = response?.data?.map((terms) => {
            return {
              title: terms?.title || "",
              description: terms?.description || "",
            };
          });
          console.log("Terms fetched successfully", terms);
          if (terms && terms.length > 0) {
            setTermsData(terms);
            return;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTerms();
  }, []);

  const validateFields = () => {
    let isValid = true;

    const errors = termsData.map(() => ({
      title: "",
      description: "",
    }));

    termsData.forEach((term, index) => {
      // Validate title (same as before)
      if (!term?.title || !term.title.trim()) {
        errors[index].title = "Title is required.";
        isValid = false;
      }

      // Check for description content in ReactQuill
      const descriptionText = term?.description?.replace(/<[^>]+>/g, "").trim(); // Extract plain text by stripping HTML tags

      if (!descriptionText) {
        errors[index].description = "Description is required.";
        isValid = false;
      }
    });

    setTermsError(errors);
    return isValid;
  };

  const addTerms = async () => {
    if (!validateFields()) {
      return;

      // Proceed with submission logic
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/addTerms`,
        { terms: termsData }
      );
      if (response.status === 201) {
        navigate("/admin/carousel");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Admin>
      <Stack gap="20px">
        <RobotoTypo
          sx={{
            textAlign: "center",
            width: "fit-content",
            fontSize: { xs: 18, sm: 20 },
            color: "#003B5C",
            fontWeight: 700,
            mb: 1,
          }}
        >
          Terms and Condition :
        </RobotoTypo>
        {termsData.map((item, index) => (
          <Stack
            gap="20px"
            sx={{ border: "1px solid #ccc", borderRadius: "5px" }}
            py="20px"
            px="20px"
          >
            <Box>
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 18, sm: 20 },
                  color: "#003B5C",
                  fontWeight: 700,
                  mb: 1,
                }}
              >
                Title
              </RobotoTypo>
              <TextField
                name="title"
                value={item?.title}
                onChange={(e) => handleChange(index, e)}
                variant="outlined"
                sx={{ width: "100%" }}
              />
              {termsError[index]?.title && (
                <p style={{ color: "red" }}>{termsError[index].title}</p>
              )}
            </Box>
            <Box>
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 18, sm: 18 },
                  color: "#003B5C",
                  fontWeight: 700,
                  mb: 2,
                }}
              >
                Description
              </RobotoTypo>
              <ReactQuill
                value={item?.description}
                name="description"
                onChange={(e) => {
                  handleDescriptionChange(index, e);
                }}
                modules={module}
                theme="snow"
                placeholder=""
              />
              {termsError[index]?.description && (
                <p style={{ color: "red" }}>{termsError[index].description}</p>
              )}
            </Box>
            {index > 0 && (
              <Box
                width="100%"
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Button
                  sx={{
                    background: "red",
                  }}
                  variant="contained"
                  onClick={() => {
                    handleDelete(index);
                  }}
                >
                  Delete
                </Button>
              </Box>
            )}
          </Stack>
        ))}
        <Box width="100%" sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{ background: "#177D84" }}
            variant="contained"
            onClick={handleAdd}
          >
            Add
          </Button>
        </Box>
        <Button
          onClick={addTerms}
          sx={{ background: "#177D84", width: "100% " }}
          variant="contained"
        >
          Submit
        </Button>
      </Stack>
    </Admin>
  );
};

export default AdminTermsCondition;
