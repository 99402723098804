import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useSelector } from "react-redux";
import Image1 from "../../assets/Carouselimages/DR_carousel_1.png";
import Image2 from "../../assets/Carouselimages/DR_carousel_2.png";
import Image3 from "../../assets/Carouselimages/DR_carousel_3.png";
import Image4 from "../../assets/Carouselimages/DR_carousel_4.png";
import { Box, Button } from "@mui/material";
import { RobotoTypo, TimesNewTypo } from "../../utils/Typographies";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { actions } from "../../redux/slice";
import { useNavigate } from "react-router-dom";

const Images = [Image1, Image2, Image3, Image4];

const ImageCarousel = () => {
  const { t } = useTranslation();
  const [CurrentPage, SetCurrentPage] = useState(1);
  const [carouselData, setCarouselData] = useState([]);
  const [data, setData] = useState([]);
  const CarouselRef = useRef();
  const language = useSelector((state) => state.language.language);

  const handlePageChange = (number) => {
    console.log("number ", number);
    CarouselRef.current.handleClickThumb(number - 1);
  };

  const getCarousel = async () => {
    console.log("useEffect ", CurrentPage);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getCarousel`
      );
      console.log("response get ", response);
      if (response.status == 200) {
        setCarouselData(response.data);
        getData(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCarousel();
  }, []);

  const getData = (data) => {
    console.log("language ", language);
    const languageData = data.map((item) => {
      if (language === "en") {
        return item?.en;
      } else if (language === "sch") {
        return item?.sch || item?.en; // Fallback to 'en' if 'sch' is not present
      } else {
        return item?.ch;
      }
    });
    setData(languageData);
  };

  useEffect(() => {
    console.log("carouselData ", carouselData);
    if (carouselData?.length != 0) {
      getData(carouselData);
    }
  }, [language]);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {data?.length != 0 && (
        <Carousel
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3500}
          showStatus={false}
          showArrows={false}
          ref={CarouselRef}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              SetCurrentPage(index + 1);
            }
            return;
          }}
        >
          {data?.map((item, index) => (
            <div key={index} style={{}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", lg: "flex-end" },
                  height: { xs: 240, lg: 700 },
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    height: "inherit",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <img
                    // src={item}
                    src={`${process.env.REACT_APP_FILE_URL}${item.image}`}
                    alt={`CarouselImage${index + 1}`}
                    style={{
                      objectFit: "contain",
                      height: "inherit",
                      width: "100%",
                    }}
                  />
                </Box>
              </Box>
            </div>
          ))}
        </Carousel>
      )}

      <Box
        sx={{
          borderTopRightRadius: { xs: 0, lg: "100px" },
          position: { xs: "relative", lg: "absolute" },
          bottom: 0,
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          zIndex: 99,
        }}
      >
        <Box
          sx={{
            height: { xs: "100%", lg: "45%" },
            width: { xs: "100%", lg: "60%" },
            display: { xs: "none", lg: "flex" },
          }}
        >
          <Box sx={{ borderRight: "8px solid #F5DFBA", width: "80px" }}></Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: { xs: "100%", lg: "55%" },
            width: { xs: "100%", lg: "60%" },
          }}
        >
          <Box
            sx={{
              height: "100%",
              background: "#F5DFBA",
            }}
          >
            <Box
              sx={{
                height: "70%",
                borderBottomRightRadius: { xs: 0, lg: "100px" },
                borderRight: { xs: 0, lg: "8px solid white" },
                borderBottom: { xs: 0, lg: "8px solid white" },
                background: "#F5DFBA",
                width: "80px",
                display: { xs: "none", lg: "flex" },
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              background: "#F5DFBA",
              borderTopRightRadius: { xs: 0, lg: "100px" },
              p: { xs: 3, md: 4, lg: 5 },
              height: { xs: "100%", lg: "90%" },
              gap: { xs: 1, lg: 0 },
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <TimesNewTypo
              sx={{
                fontSize: { xs: "22px", sm: "28px", md: "40px" },
                color: "#003B5C",
                fontWeight: 700,
                lineHeight: { xs: 1.3, lg: 1.1 },
              }}
            >
              {
                // t('CarouselPassage', { returnObjects: true })[CurrentPage - 1]
                //   ?.title
                data[CurrentPage - 1]?.title
              }
            </TimesNewTypo>
            <RobotoTypo
              sx={{ fontSize: { xs: 13, sm: 15, lg: "16px" }, fontWeight: 400 }}
            >
              {
                // t('CarouselPassage', { returnObjects: true })[CurrentPage - 1]
                //   ?.passage
                data[CurrentPage - 1]?.description
              }
            </RobotoTypo>

            <Button
              variant="contained"
              sx={{
                borderRadius: "25px",
                background: "#177D84",
                boxShadow: 5,
                fontSize: { xs: 11, lg: 15 },
              }}
              onClick={() => navigate(`${data[CurrentPage - 1]?.url}`)}
            >
              {t("CorouselMoreButton")}
            </Button>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                gap: 2,
              }}
            >
              {data?.map((item, index) => (
                <Box
                  sx={{
                    width: { xs: 33, lg: 44 },
                    height: { xs: 9, lg: 12 },
                    background:
                      index + 1 === CurrentPage ? "#177D84" : "#D9D9D9",
                    borderRadius: "8px",
                    cursor: "pointer",
                    transition: "ease .5s",
                  }}
                  onClick={() => handlePageChange(index + 1)}
                ></Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageCarousel;
