import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Box, Stack } from "@mui/system";
import {
  Typography,
  Card,
  CardContent,
  Slider,
  Button,
  Checkbox,
  Link,
} from "@mui/material";
import { RobotoTypo } from "../../utils/Typographies";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { useTranslation } from "react-i18next";
import customSlider from "../../utils/theme";
import correctImg from "../../assets/svg/correct.png";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import SuccessIcon from "../../assets/payment/Vector.svg";
import {
  studentBox,
  studentContent,
  studentOuterBox,
  studentTitle,
} from "./UniversityView";
import ToastAlert from "../../utils/Alert";
import TermsAndConditions from "../../components/ApplicationForm/University/TermsCondition";

function SchoolView() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [toastStatus, setToastStatus] = useState({
    color: "",
    status: false,
    message: "",
    icon: "",
  });
  const [loading, setLoading] = useState(false);

  const [applicationData, setApplicationData] = useState({});

  const fetchApplicationData = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/student-application/${id}`)
      .then((response) => {
        console.log(response.data);
        setApplicationData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    if (e) {
      e.preventDefault();
    }
    try {
      console.log("Attempting to open OmiseCard");
      if (typeof window.OmiseCard !== "undefined") {
        const amount = applicationData?.StudentDetails?.application_fee * 100;
        if (!amount || amount <= 0) {
          console.error("Invalid amount:", amount);
          return;
        }
        console.log("Opening OmiseCard with amount:", amount);
        window.OmiseCard.open({
          amount: amount,
          currency: "S$",
          frameLabel: "Dr_tech International",
          submitLabel: "Pay Now",
          otherPaymentMethods: [
            "alipay_hk",
            "kakaopay",
            "touch_n_go",
            "wechat_pay",
            "paynow",
          ],
          onCreateTokenSuccess: handleOmiseResponse,
          onFormClosed: () => {
            console.log("Payment form closed");
            setLoading(false);
            // Reset any relevant state here if needed
          },
        });
      } else {
        console.error("OmiseCard is not available");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  const handleOmiseResponse = async (tokenID) => {
    console.log("called omise response");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/charge`,
        {
          token: tokenID,
          user_id: applicationData?.StudentDetails?.user_id,
          application_id: applicationData?._id,
          amount: applicationData?.StudentDetails?.application_fee * 100,
          user_email: applicationData?.StudentDetails?.personalDetails?.email,
        }
      );
      console.log(response?.data);
      localStorage.setItem("payment_id", response?.data?.id);
      setLoading(false);
      if (response.data.authorize_uri) {
        window.location.href = response.data.authorize_uri;
      } else {
        console.log("Charge response:", response.data.authorize_uri);
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      setToastStatus({
        color: "red",
        status: true,
        icon: "close",
        message: error.response.data?.error || "Invalid data provided!",
      });
      setLoading(false);
    }
  };
  const handleCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
    setTermsError(false); // Clear error when the checkbox is checked
  };
  const downloadInvoice = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/generateInvoicePdf/${id}`,
        {
          responseType: "blob", // Ensure the response is treated as a binary Blob
        }
      );

      // Create a URL for the Blob and download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice_${id}.pdf`); // Set the file name
      document.body.appendChild(link);
      link.click();

      // Clean up the link after download
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the invoice:", error);
    }
    // axios
    //   .get(`${process.env.REACT_APP_BASE_URL}/generateInvoicePdf/${id}`)
    //   .then((response) => {
    //     console.log(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
  useEffect(() => {
    if (id) {
      fetchApplicationData();
    }
  }, [id]);

  const paymentIcon = (status) => {
    switch (status) {
      case "successful":
        return (
          <img
            style={{ width: "40px", height: "40px" }}
            alt="payment-indicator"
            src={SuccessIcon}
          />
        );

      case "pending":
        return <PendingActionsIcon sx={{ color: "#FF7900 " }} />;

      default:
        return <SmsFailedIcon sx={{ color: "red" }} />;
    }
  };
  const paymentTitle = (status) => {
    switch (status) {
      case "successful":
        return "Payment was successful ";

      case "pending":
        return "Your payment is currently pending. ";

      default:
        return "Payment failed.";
    }
  };
  const generatePaymentStatus = (status, applicationData) => {
    console.log("status", status);
    if (status !== undefined) {
      console.log("status1", status);
      return (
        <Stack py={"60px"} alignItems={"center"} gap={"20px"}>
          <>
            {" "}
            <style>
              {`
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
        
        .poppins-text {
            font-family: 'Poppins', sans-serif;
        }
    `}
            </style>
          </>

          <Box width="100%" display={"flex"} justifyContent={"center"}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                backgroundColor:
                  status === "successful"
                    ? "rgba(35, 162, 109, 0.12)"
                    : status === "pending"
                    ? "rgba(255, 121, 0, 0.12)"
                    : "rgba(255, 0, 0, 0.12)",
              }}
            >
              <Box
                sx={{ width: "60px", height: "60px" }}
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
              >
                {paymentIcon(status)}
              </Box>
            </Box>
          </Box>
          <Typography
            sx={{
              color: "#474747",
              fontFamily: "poppins",
              fontWeight: "400",
              fontSize: "14px",
            }}
          >
            {paymentTitle(status)}
          </Typography>
          <Box
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <RobotoTypo sx={{ zIndex: 2, fontSize: "1.4rem" }}>S$ {" "}</RobotoTypo>{" "}
            <RobotoTypo
              sx={{
                fontSize: "2.5rem",
                fontWeight: 700,
                zIndex: 3,
                background: "linear-gradient(90deg, #23074d 0%, #cc5333 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {" "}
              {applicationData?.StudentDetails?.payment_details?.amount /
                100}{" "}
            </RobotoTypo>{" "}
          </Box>
          {status === "successful" && (
            <Button variant="contained" onClick={downloadInvoice}>
              Download Invoice
            </Button>
          )}
        </Stack>
      );
    }
  };
  return (
    <>
      <ToastAlert
        message={toastStatus?.message}
        status={toastStatus?.status}
        color={toastStatus?.color}
        icon={toastStatus?.icon}
        closeFunc={() => setToastStatus({ color: "", status: false })}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
        }}
      >
        <Card sx={{ m: 0, width: { xs: "100%", sm: "75%" } }}>
          <CardContent
            sx={{
              p: 2,
              m: 0,
              border: "1px solid #e5e5e5",
              overflowY: "auto",
              height: "100vh",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "1px solid #e5e5e5",
                display: "flex",
                alignItems: "center",
                gap: 3,
                justifyContent: "flex-start",
              }}
            >
              <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
              <RobotoTypo
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "16px", sm: "1.2rem" },
                  color: "#177D84",
                }}
              >
                Personal Details
              </RobotoTypo>
            </Box>

            <Box sx={{ border: "1px solid #e5e5e5", p: 2, m: 2 }}>
              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.name", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails?.name ||
                      "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.passport_name", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails
                      ?.passport_name || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.religious_beliefs", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails
                      ?.religious_belief || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.country_of_birth", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails
                      ?.country_of_birth || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.school_name", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails
                      ?.school_name || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.recent_grade_attended", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails
                      ?.recent_grade_attended || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Expected_Enrolment_Date", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails
                      ?.expected_enrolment_date || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Grade_Applying_For", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails
                      ?.grade_applying_for || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.School_Address", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails
                      ?.school_address || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.School_Contact_Phone", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails
                      ?.school_contact || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.School_Contact_Email", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails
                      ?.school_email || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Teacher_Name", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails
                      ?.teacher_name || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Teacher_Email", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails
                      ?.teacher_email || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Residential_Address", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails
                      ?.residential_address || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Address_five_years_one", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.personalDetails
                      ?.Is_lived_in_pastfiveyears || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentBox}></Box>
            </Box>

            <Box
              sx={{
                mt: 3,
                p: 2,
                borderTop: "1px solid #e5e5e5",
                borderBottom: "1px solid #e5e5e5",
                display: "flex",
                alignItems: "center",
                gap: 3,
                justifyContent: "flex-start",
              }}
            >
              <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
              <RobotoTypo
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "16px", sm: "1.2rem" },
                  color: "#177D84",
                }}
              >
                {t("ApplicationForm.Language_Survey", { returnObjects: true })}
              </RobotoTypo>
            </Box>

            <RobotoTypo sx={{ m: 2.5, fontWeight: 600, color: "#003B5C" }}>
              1.{" "}
              {t("ApplicationForm.Primary_Language", { returnObjects: true })}
            </RobotoTypo>
            {applicationData?.StudentDetails?.personalDetails?.primary_languages.map(
              (data, i) => (
                <>
                  <Box sx={{ border: "1px solid #e5e5e5", p: 2, m: 2 }}>
                    <Box sx={studentOuterBox}>
                      <Box sx={studentBox}>
                        <Typography sx={studentTitle}>
                          {t("ApplicationForm.Language", {
                            returnObjects: true,
                          })}
                        </Typography>
                        <Typography sx={studentContent}>
                          {data?.name || "-"}
                        </Typography>
                      </Box>

                      <Box sx={studentBox}>
                        <Typography sx={studentTitle}>
                          {t("ApplicationForm.Level", { returnObjects: true })}
                        </Typography>
                        <Typography sx={studentContent}>
                          {data?.level || "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              )
            )}

            <RobotoTypo sx={{ m: 2.5, fontWeight: 600, color: "#003B5C" }}>
              2.{" "}
              {t("ApplicationForm.languages_level_title", {
                returnObjects: true,
              })}
            </RobotoTypo>
            {applicationData?.StudentDetails?.personalDetails?.language_levels.map(
              (data, i) => (
                <>
                  <Box sx={{ border: "1px solid #e5e5e5", p: 2, m: 2 }}>
                    <Box sx={studentOuterBox}>
                      <Box sx={studentBox}>
                        <Typography sx={studentTitle}>
                          {t("ApplicationForm.Language", {
                            returnObjects: true,
                          })}
                        </Typography>
                        <Typography sx={studentContent}>
                          {data?.name || "-"}
                        </Typography>
                      </Box>

                      <Box sx={studentBox}>
                        <Typography sx={studentTitle}>
                          {t("ApplicationForm.Reading", {
                            returnObjects: true,
                          })}
                        </Typography>
                        <Typography sx={studentContent}>
                          {data?.reading || "-"}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={studentOuterBox}>
                      <Box sx={studentBox}>
                        <Typography sx={studentTitle}>
                          {t("ApplicationForm.Speaking", {
                            returnObjects: true,
                          })}
                        </Typography>
                        <Typography sx={studentContent}>
                          {data?.speaking || "-"}
                        </Typography>
                      </Box>

                      <Box sx={studentBox}>
                        <Typography sx={studentTitle}>
                          {t("ApplicationForm.Writing", {
                            returnObjects: true,
                          })}
                        </Typography>
                        <Typography sx={studentContent}>
                          {data?.writing || "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              )
            )}

            <Box sx={studentOuterBox}>
              <Box sx={studentBox}>
                <RobotoTypo sx={{ m: 2.5, fontWeight: 600, color: "#003B5C" }}>
                  3.{" "}
                  {t(
                    "ApplicationForm.How_many_years_have_you_learned_English",
                    {
                      returnObjects: true,
                    }
                  )}
                </RobotoTypo>
                <Typography sx={studentContent} px={2}>
                  {applicationData?.StudentDetails?.personalDetails
                    ?.years_learned_english || "-"}
                </Typography>
              </Box>

              <Box sx={studentBox}>
                {/* <Typography sx={studentTitle}>{t('ApplicationForm.Reading', { returnObjects: true })}</Typography>
                        <Typography sx={studentContent}>{data?.reading || '-'}</Typography> */}
              </Box>
            </Box>

            <RobotoTypo sx={{ m: 2.5, fontWeight: 600, color: "#003B5C" }}>
              4.{" "}
              {t("ApplicationForm.what_kind_of_person_title", {
                returnObjects: true,
              })}
            </RobotoTypo>
            {applicationData?.StudentDetails?.personalDetails?.kind_of_person.map(
              (data, i) => (
                <>
                  <Box sx={{ border: "1px solid #e5e5e5", p: 2, m: 2 }}>
                    <Stack
                      columnGap={5}
                      direction={{ xs: "column", md: "row" }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <RobotoTypo
                        sx={{
                          fontWeight: 500,
                          color: "#003B5C",
                          fontSize: { xs: "14px", sm: "16px" },
                          minWidth: "140px",
                          textAlign: "center",
                        }}
                      >
                        {t(`ApplicationForm.${data?.name1}`, {
                          returnObjects: true,
                        })}
                      </RobotoTypo>
                      <Slider
                        disabled
                        value={data?.value}
                        name="value"
                        step={10}
                        sx={{
                          color: "#bdbdbd",
                          "&.Mui-disabled": {
                            color: "#009688",
                            cursor: "not-allowed",
                            "& .MuiSlider-thumb": {
                              backgroundColor: "#009688",
                            },
                            "& .MuiSlider-track": {
                              backgroundColor: "#d1ece9",
                            },
                            "& .MuiSlider-rail": {
                              backgroundColor: "#66c0b7",
                            },
                          },
                        }}
                      />
                      <RobotoTypo
                        sx={{
                          fontWeight: "500",
                          color: "#003B5C",
                          fontSize: { xs: "14px", sm: "16px" },
                          minWidth: "140px",
                          textAlign: "center",
                        }}
                      >
                        {t(`ApplicationForm.${data?.name2}`, {
                          returnObjects: true,
                        })}
                      </RobotoTypo>
                    </Stack>
                  </Box>
                </>
              )
            )}

            <Box
              sx={{
                p: 2,
                borderBottom: "1px solid #e5e5e5",
                borderTop: "1px solid #e5e5e5",
                display: "flex",
                alignItems: "center",
                gap: 3,
                justifyContent: "flex-start",
              }}
            >
              <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
              <RobotoTypo
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "16px", sm: "1.2rem" },
                  color: "#177D84",
                }}
              >
                {t("ApplicationForm.father_info_title", {
                  returnObjects: true,
                })}
              </RobotoTypo>
            </Box>
            <Box sx={{ border: "1px solid #e5e5e5", p: 2, m: 2 }}>
              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.name", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.fatherDetails?.name || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Average_monthly_income", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.fatherDetails?.avg_month_sal || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Current_deposit", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.fatherDetails?.current_deposit || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Phone", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.fatherDetails?.phone || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Email", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.fatherDetails?.email || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Highest_education_level", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.fatherDetails?.high_edu_level || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Occupation_type", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.fatherDetails?.occupation_type || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Position", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.fatherDetails?.position || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Company_Name", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.fatherDetails?.company_name || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Company_Phone", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.fatherDetails?.company_phone || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Company_Address", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.fatherDetails?.company_address || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Is_company_UnitedStates", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.fatherDetails?.Is_company_US || "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* -------------------------------------- */}

            <Box
              sx={{
                p: 2,
                borderBottom: "1px solid #e5e5e5",
                borderTop: "1px solid #e5e5e5",
                display: "flex",
                alignItems: "center",
                gap: 3,
                justifyContent: "flex-start",
              }}
            >
              <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
              <RobotoTypo
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "16px", sm: "1.2rem" },
                  color: "#177D84",
                }}
              >
                {t("ApplicationForm.mother_info_title", {
                  returnObjects: true,
                })}
              </RobotoTypo>
            </Box>
            <Box sx={{ border: "1px solid #e5e5e5", p: 2, m: 2 }}>
              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.name", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.motherDetails?.name || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Average_monthly_income", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.motherDetails?.avg_month_sal || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Current_deposit", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.motherDetails?.current_deposit || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Phone", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.motherDetails?.phone || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Email", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.motherDetails?.email || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Highest_education_level", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.motherDetails?.high_edu_level || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Occupation_type", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.motherDetails?.occupation_type || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Position", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.motherDetails?.position || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Company_Name", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.motherDetails?.company_name || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Company_Phone", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.motherDetails?.company_phone || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Company_Address", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.motherDetails?.company_address || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Is_company_UnitedStates", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.motherDetails?.Is_company_US || "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                p: 2,
                borderBottom: "1px solid #e5e5e5",
                borderTop: "1px solid #e5e5e5",
                display: "flex",
                alignItems: "center",
                gap: 3,
                justifyContent: "flex-start",
              }}
            >
              <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
              <RobotoTypo
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "16px", sm: "1.2rem" },
                  color: "#177D84",
                }}
              >
                {t("ApplicationForm.Applicant_Siblings_Information", {
                  returnObjects: true,
                })}
              </RobotoTypo>
            </Box>
            {applicationData?.StudentDetails?.familyDetails?.SiblingsInfo.map(
              (data, i) => (
                <>
                  <Box sx={{ border: "1px solid #e5e5e5", p: 2, m: 2 }}>
                    <Box sx={studentOuterBox}>
                      <Box sx={studentBox}>
                        <Typography sx={studentTitle}>
                          {t("ApplicationForm.name", { returnObjects: true })}
                        </Typography>
                        <Typography sx={studentContent}>
                          {data?.name || "-"}
                        </Typography>
                      </Box>

                      <Box sx={studentBox}>
                        <Typography sx={studentTitle}>
                          {t("ApplicationForm.passport_name", {
                            returnObjects: true,
                          })}
                        </Typography>
                        <Typography sx={studentContent}>
                          {data?.passport_name || "-"}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={studentOuterBox}>
                      <Box sx={studentBox}>
                        <Typography sx={studentTitle}>
                          {t("ApplicationForm.Relationship", {
                            returnObjects: true,
                          })}
                        </Typography>
                        <Typography sx={studentContent}>
                          {data?.relationship || "-"}
                        </Typography>
                      </Box>

                      <Box sx={studentBox}>
                        <Typography sx={studentTitle}>
                          {t("ApplicationForm.dob", { returnObjects: true })}
                        </Typography>
                        <Typography sx={studentContent}>
                          {data?.dob || "-"}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={studentOuterBox}>
                      <Box sx={studentBox}>
                        <Typography sx={studentTitle}>
                          {t("ApplicationForm.Nationality", {
                            returnObjects: true,
                          })}
                        </Typography>
                        <Typography sx={studentContent}>
                          {data?.nationality || "-"}
                        </Typography>
                      </Box>

                      <Box sx={studentBox}></Box>
                    </Box>
                  </Box>
                </>
              )
            )}

            <Box
              sx={{
                p: 2,
                borderBottom: "1px solid #e5e5e5",
                borderTop: "1px solid #e5e5e5",
                display: "flex",
                alignItems: "center",
                gap: 3,
                justifyContent: "flex-start",
              }}
            >
              <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
              <RobotoTypo
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "16px", sm: "1.2rem" },
                  color: "#177D84",
                }}
              >
                {t("ApplicationForm.Applicant_Grandmother_Information", {
                  returnObjects: true,
                })}
              </RobotoTypo>
            </Box>
            <Box sx={{ border: "1px solid #e5e5e5", p: 2, m: 2 }}>
              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.name", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.grandmotherInfo?.name || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.passport_name", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.grandmotherInfo?.passport_name || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Relationship", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.grandmotherInfo?.relationship || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.dob", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.grandmotherInfo?.dob || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Nationality", { returnObjects: true })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.familyDetails
                      ?.grandmotherInfo?.nationality || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentBox}></Box>
            </Box>

            <Box
              sx={{
                p: 2,
                borderBottom: "1px solid #e5e5e5",
                borderTop: "1px solid #e5e5e5",
                display: "flex",
                alignItems: "center",
                gap: 3,
                justifyContent: "flex-start",
              }}
            >
              <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
              <RobotoTypo
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "16px", sm: "1.2rem" },
                  color: "#177D84",
                }}
              >
                {t("ApplicationForm.Development_health_history_Title", {
                  returnObjects: true,
                })}
              </RobotoTypo>
            </Box>
            <Box sx={{ border: "1px solid #e5e5e5", p: 2, m: 2 }}>
              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Development_health_history_1", {
                      returnObjects: true,
                    })}
                  </Typography>

                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.otherDetails
                      ?.health_history_Details?.have_physical_disability || "-"}
                  </Typography>
                  {applicationData?.StudentDetails?.otherDetails
                    ?.health_history_Details?.have_physical_disability_des && (
                    <Typography sx={{ ...studentContent, mt: 1 }}>
                      {applicationData?.StudentDetails?.otherDetails
                        ?.health_history_Details
                        ?.have_physical_disability_des || "-"}
                    </Typography>
                  )}
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Development_health_history_2", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.otherDetails
                      ?.health_history_Details?.have_serious_hlth_prob || "-"}
                  </Typography>
                  {applicationData?.StudentDetails?.otherDetails
                    ?.health_history_Details?.have_serious_hlth_prob_des && (
                    <Typography sx={{ ...studentContent, mt: 1 }}>
                      {applicationData?.StudentDetails?.otherDetails
                        ?.health_history_Details?.have_serious_hlth_prob_des ||
                        "-"}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Development_health_history_3", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.otherDetails
                      ?.health_history_Details?.difficulty_In_physical_skills ||
                      "-"}
                  </Typography>
                  {applicationData?.StudentDetails?.otherDetails
                    ?.health_history_Details
                    ?.difficulty_In_physical_skills_des && (
                    <Typography sx={{ ...studentContent, mt: 1 }}>
                      {applicationData?.StudentDetails?.otherDetails
                        ?.health_history_Details
                        ?.difficulty_In_physical_skills_des || "-"}
                    </Typography>
                  )}
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Development_health_history_4", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.otherDetails
                      ?.health_history_Details?.had_trouble_paying_attention ||
                      "-"}
                  </Typography>
                  {applicationData?.StudentDetails?.otherDetails
                    ?.health_history_Details
                    ?.had_trouble_paying_attention_des && (
                    <Typography sx={{ ...studentContent, mt: 1 }}>
                      {applicationData?.StudentDetails?.otherDetails
                        ?.health_history_Details
                        ?.had_trouble_paying_attention_des || "-"}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Development_health_history_5", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.otherDetails
                      ?.health_history_Details?.have_allergies || "-"}
                  </Typography>
                  {applicationData?.StudentDetails?.otherDetails
                    ?.health_history_Details?.have_allergies_des && (
                    <Typography sx={{ ...studentContent, mt: 1 }}>
                      {applicationData?.StudentDetails?.otherDetails
                        ?.health_history_Details?.have_allergies_des || "-"}
                    </Typography>
                  )}
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Development_health_history_6", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.otherDetails
                      ?.health_history_Details?.Is_taking_any_medication || "-"}
                  </Typography>
                  {applicationData?.StudentDetails?.otherDetails
                    ?.health_history_Details?.Is_taking_any_medication_des && (
                    <Typography sx={{ ...studentContent, mt: 1 }}>
                      {applicationData?.StudentDetails?.otherDetails
                        ?.health_history_Details
                        ?.Is_taking_any_medication_des || "-"}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Development_health_history_7", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.otherDetails
                      ?.health_history_Details?.can_use_toilet_independently ||
                      "-"}
                  </Typography>
                  {applicationData?.StudentDetails?.otherDetails
                    ?.health_history_Details
                    ?.can_use_toilet_independently_des && (
                    <Typography sx={{ ...studentContent, mt: 1 }}>
                      {applicationData?.StudentDetails?.otherDetails
                        ?.health_history_Details
                        ?.can_use_toilet_independently_des || "-"}
                    </Typography>
                  )}
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Development_health_history_8", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.otherDetails
                      ?.health_history_Details?.can_wear_his_clothes || "-"}
                  </Typography>
                  {applicationData?.StudentDetails?.otherDetails
                    ?.health_history_Details?.can_wear_his_clothes_des && (
                    <Typography sx={{ ...studentContent, mt: 1 }}>
                      {applicationData?.StudentDetails?.otherDetails
                        ?.health_history_Details?.can_wear_his_clothes_des ||
                        "-"}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                p: 2,
                borderBottom: "1px solid #e5e5e5",
                borderTop: "1px solid #e5e5e5",
                display: "flex",
                alignItems: "center",
                gap: 3,
                justifyContent: "flex-start",
              }}
            >
              <ArticleOutlinedIcon sx={{ color: "#177D84" }} />
              <RobotoTypo
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "16px", sm: "1.2rem" },
                  color: "#177D84",
                }}
              >
                {t("ApplicationForm.Applicants_American_School_Singapore", {
                  returnObjects: true,
                })}
              </RobotoTypo>
            </Box>

            <RobotoTypo sx={{ m: 2.5, fontWeight: 600, color: "#003B5C" }}>
              1.{" "}
              {t("ApplicationForm.Applicant_Father", { returnObjects: true })}
            </RobotoTypo>
            <Box sx={{ border: "1px solid #e5e5e5", p: 2, m: 2 }}>
              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.favorite_hobby", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.otherDetails
                      ?.american_school_sg?.father_fav_hobby || "-"}
                  </Typography>
                </Box>

                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.Is_interesting_you’d_like_us_to_know", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.otherDetails
                      ?.american_school_sg?.father_intersting_thing || "-"}
                  </Typography>
                </Box>
              </Box>

              <RobotoTypo sx={{ m: 2.5, fontWeight: 600, color: "#003B5C" }}>
                2.{" "}
                {t("ApplicationForm.Applicant_Mother", { returnObjects: true })}
              </RobotoTypo>

              <Box sx={studentOuterBox}>
                <Box sx={studentBox}>
                  <Typography sx={studentTitle}>
                    {t("ApplicationForm.favorite_hobby", {
                      returnObjects: true,
                    })}
                  </Typography>
                  <Typography sx={studentContent}>
                    {applicationData?.StudentDetails?.otherDetails
                      ?.american_school_sg?.mother_fav_hobby || "-"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={studentBox}>
                <Typography sx={studentTitle}>
                  {t("ApplicationForm.Is_interesting_you’d_like_us_to_know", {
                    returnObjects: true,
                  })}
                </Typography>
                <Typography sx={studentContent}>
                  {applicationData?.StudentDetails?.otherDetails
                    ?.american_school_sg?.mother_intersting_thing || "-"}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>

        <Card
          sx={{
            width: { xs: "100%", sm: " 36%" },
            background: "",
            p: 0,
            m: 0,
            border: "1px solid #e5e5e5",
          }}
        >
          <CardContent
            sx={{
              p: 0,
              m: 0,
              width: "100%",
              height: "100% important",
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "column",
            }}
          >
            {applicationData?.StudentDetails?.payment_details ? (
              <>
                {" "}
                {generatePaymentStatus(
                  applicationData?.StudentDetails?.payment_details?.status,
                  applicationData
                )}{" "}
              </>
            ) : (
              <>
                {" "}
                <>
                  <Stack
                    sx={{
                      padding: "18px 30px",
                      backgroundColor: "#e1e1e1",
                      mb: "30px",
                    }}
                  >
                    <RobotoTypo
                      sx={{
                        fontWeight: "500",
                        color: "#003B5C",
                        fontSize: "20px",
                        textAlign: "left",
                      }}
                    >
                      Application Fee
                    </RobotoTypo>
                  </Stack>
                  <Stack px={"40px"} gap="30px">
                    <Box className="table">
                      <table>
                        <tbody>
                          {applicationData?.StudentDetails?.services_selected?.map(
                            (row, index) => (
                              <tr key={index}>
                                <td>
                                  <RobotoTypo sx={{ fontSize: "16px" }}>
                                    {row?.title_en}
                                  </RobotoTypo>
                                </td>
                                <td>
                                  <RobotoTypo sx={{ fontSize: "16px" }}>
                                    S${" "}
                                    {isNaN(row?.amount_en) ? 0 : row?.amount_en}
                                  </RobotoTypo>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </Box>
                  </Stack>

                  <Stack
                    flexDirection={"row"}
                    px={"20px"}
                    alignItems={"center"}
                    gap="2px"
                    mt="30px"
                  >
                    <Checkbox
                      color="primary"
                      checked={termsChecked}
                      onChange={handleCheckboxChange}
                    />

                    <Typography sx={{ fontSize: "14px" }}>
                      I Agree to the{" "}
                      <Link
                        href="/terms"
                        target="_blank"
                        sx={{ cursor: "pointer" }}
                        rel="noopener"
                      >
                        Terms and Conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        href="/privacyNotice"
                        target="_blank"
                        sx={{ cursor: "pointer" }}
                        rel="noopener"
                      >
                        Privacy Notice
                      </Link>
                    </Typography>
                  </Stack>
                  {termsError && (
                    <Typography px={"28px"} color="error" variant="body2">
                      Please accept the terms and conditions to proceed.
                    </Typography>
                  )}
                </>
                {
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      py: "40px",
                    }}
                  >
                    <Typography variant="h5" gutterBottom>
                      Ready to Pay?
                    </Typography>
                    <Typography variant="body1" textAlign="center" gutterBottom>
                      Click the button below to complete your payment.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={loading}
                      sx={{ mt: 2 }}
                      onClick={(e) => {
                        if (!termsChecked) {
                          setTermsError(true);
                          return;
                        }
                        handleSubmit(e);
                      }}
                    >
                      Pay Now S${" "}
                      {applicationData?.StudentDetails?.application_fee}
                    </Button>
                  </Box>
                }
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default SchoolView;
