import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import SellIcon from "@mui/icons-material/Sell";
import LaunchIcon from "@mui/icons-material/Launch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { RobotoTypo } from "../../../utils/Typographies";

const PaymentDialog = ({
  payLater,
  paymentData,
  payNow,
  open,
  onClose,
  total,
  payNowLoading,
  payLaterLoading,
  setPaymentData,
}) => {
  const [next, setNext] = useState(false);
  const handleCheckboxChange = (index) => {
    const newPaymentData = paymentData.map((row, i) => {
      if (i === index) {
        return { ...row, isChecked: !row.isChecked };
      }
      return row;
    });
    setPaymentData(newPaymentData);
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            return; // Prevent closing on backdrop click
          }
          onClose(); // Allow other close actions
        }}
      >
        {!next ? (
          <Box sx={{ width: "100%", overflowX: "hidden" }}>
            <DialogTitle sx={{ borderBottom: "2px solid #eceef3" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography style={{ fontSize: "20px", fontWeight: 600 }}>
                  Checkout
                </Typography>
                <Close
                  onClick={() => {
                    onClose();
                    setNext(false);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </DialogTitle>
            <Stack px={"20px"} gap="20px" py="20px">
              <Typography style={{ fontSize: "18px", fontWeight: 400 }}>
                Selected Services
              </Typography>
              <Box>
                {/* Table for rows with isReadable = true */}
                <Table
                  style={{
                    borderCollapse: "separate",
                    width: "100%",
                    backgroundColor: "#F1FEFF",
                    color: "#177D84",
                    border: "2px solid #68C4CA",
                    borderRadius: "8px",
                    marginBottom: 4,
                    paddingInline: "20px",
                  }}
                >
                  <TableBody>
                    {paymentData
                      .filter((row) => row?.isReadable)
                      .map((row, index) => (
                        <TableRow key={`readable-${index}`}>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #D7EAEB", // Add horizontal border
                            }}
                          >
                            <Checkbox
                              checked={row.isChecked}
                              disabled={row.isReadable}
                              sx={{
                                "&.Mui-checked": {
                                  color: "#A4D0D3", // Add this line to set the checked color
                                },
                              }}
                              onChange={() => handleCheckboxChange(row?.id)}
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #D7EAEB", // Add horizontal border
                            }}
                          >
                            {row?.url ? (
                              <a
                                href={row?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontFamily: "Roboto, sans-serif",
                                  color: "#177D84",
                                  textDecoration: "none",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                }}
                              >
                                <LaunchIcon
                                  style={{ marginRight: 8, fontSize: 18 }}
                                />{" "}
                                {row.label}
                              </a>
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  color: "#177D84",
                                  fontWeight: "400",
                                }}
                              >
                                {row.label}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #D7EAEB", // Add horizontal border
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "16px", color: "#177D84" }}
                            >
                              S$ {isNaN(row.charge) ? 0 : row.charge} /-
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>

              {/* Table for rows with isReadable = false */}
              <Box>
                <Table
                  sx={{
                    backgroundColor: "#FFFFFF", // Default background for non-readable rows
                    color: "#242424",
                    border: "2px solid #BABABA",
                    borderRadius: "8px",

                    borderCollapse: "separate",

                    paddingInline: "20px",
                  }}
                >
                  <TableBody>
                    {paymentData
                      .filter((row) => !row?.isReadable)
                      .map((row, index) => (
                        <TableRow key={`non-readable-${index}`}>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #D7EAEB", // Add horizontal border
                            }}
                          >
                            <Checkbox
                              checked={row.isChecked}
                              disabled={row.isReadable}
                              sx={{
                                "&.Mui-checked": {
                                  color: "#242424", // Add this line to set the checked color
                                },
                                "& .MuiSvgIcon-root": {
                                  color: "#242424", // Border color when not checked
                                },
                                "&.Mui-checked .MuiSvgIcon-root": {
                                  color: "#242424", // Border color when checked
                                },
                              }}
                              onChange={() => handleCheckboxChange(row?.id)}
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #D7EAEB", // Add horizontal border
                            }}
                          >
                            {row?.url ? (
                              <a
                                href={row?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontFamily: "Roboto, sans-serif",
                                  color: "#242424",
                                  textDecoration: "none",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                }}
                              >
                                <LaunchIcon
                                  style={{ marginRight: 8, fontSize: 18 }}
                                />{" "}
                                {row.label}
                              </a>
                            ) : (
                              <Typography sx={{ fontSize: "16px" }}>
                                {row.label}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #D7EAEB", // Add horizontal border
                            }}
                          >
                            <Typography sx={{ fontSize: "16px" }}>
                              S$ {isNaN(row.charge) ? 0 : row.charge}/-
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
              <Box display="flex" justifyContent={"space-between"}>
                <RobotoTypo
                  sx={{ fontWeight: "700", color: "#177D84", fontSize: "20px" }}
                >
                  Total Amount
                </RobotoTypo>
                <RobotoTypo
                  sx={{ fontWeight: "700", color: "#177D84", fontSize: "20px" }}
                >
                  S$ {total} /-
                </RobotoTypo>
              </Box>
            </Stack>

            <Box
              display="flex"
              justifyContent={"flex-end"}
              sx={{ padding: "16px" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setNext(true);
                }}
                sx={{
                  background: "#177D84",
                  borderRadius: "12px",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  "&:hover": {
                    background: "#145E63", // Slightly darker shade for hover
                  },
                }}
              >
                Confirm Payment
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <DialogTitle sx={{ borderBottom: "2px solid #eceef3" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography style={{ fontSize: "20px", fontWeight: 600 }}>
                  {" "}
                  Confirm Application
                </Typography>
                <Close
                  onClick={() => {
                    onClose();
                    setNext(false);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </DialogTitle>
            <DialogContent style={{ padding: "30px 24px" }}>
              <Typography>
                Please Confirm the Application Using Pay Now or Pay Later.
              </Typography>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "15px 24px",
                borderTop: "2px solid #eceef3",
              }}
            >
              <Button
                onClick={() => {
                  payLater(paymentData, total);
                  onClose();
                  setNext(false);
                }}
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#B7CFDA",
                  padding: "8px 14px",
                  color: "#0E5E84",
                }}
                disabled={payLaterLoading}
              >
                Pay Later
              </Button>
              <Button
                onClick={() => {
                  payNow(paymentData, total);
                  onClose();
                  setNext(false);
                }}
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#0E5E84",
                  padding: "8px 14px",
                  color: "#FFFFFF",
                }}
                disabled={payNowLoading}
              >
                Pay Now
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default PaymentDialog;
