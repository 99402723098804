import {
  Box,
  Container,
  Paper,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";

const PrivacyPolicy = () => {
  const [termsData, setTermsData] = useState([]);

  const getPolicy = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getPolicy`
      );

      if (response.status === 200) {
        if (response.data.length > 0) {
          const terms = response?.data?.map((terms) => {
            return {
              title: terms?.title || "",
              description: terms?.description || "",
            };
          });
          console.log("Policy fetched successfully", terms);
          if (terms && terms.length > 0) {
            setTermsData(terms);
            return;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPolicy();
  }, []);
  return (
    <>
      <Header />

      <Box
        sx={{
          backgroundColor: "#f7f7f7", // Light gray background to balance vibrant yellow and teal
          minHeight: "100vh",
          py: 6,
          fontFamily: '"Roboto", sans-serif', // Set Roboto as the font family globally
        }}
      >
        <Container maxWidth="lg">
          {/* Header Section */}
          <Paper
            elevation={3}
            sx={{
              backgroundColor: "#fff",
              padding: 4,
              borderRadius: 4,
              mb: 5,
              position: "sticky",
              top: 0,
              zIndex: 1000,
              boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              variant="h5"
              component="h1"
              align="center"
              color="#003B5C" // Teal for the main title
              sx={{
                fontWeight: 700,
                letterSpacing: 1,
                mb: 1,
              }}
            >
              Privacy Notice
            </Typography>
          </Paper>

          {/* Content Section */}
          <Paper
            elevation={2}
            sx={{
              backgroundColor: "#fff",
              padding: 5,
              borderRadius: 4,
              boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.08)",
            }}
          >
            {termsData?.map((term, index) => (
              <Box sx={{ mb: 5 }}>
                <Typography
                  variant="h5"
                  color="#003B5C" // Use vibrant yellow for section headings
                  sx={{
                    mb: 3,
                    fontWeight: 600,
                  }}
                >
                  {term?.title}
                </Typography>
                <List>
                  <ListItem sx={{ display: "flex", alignItems: "flex-start" }}>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: "#003B5C", fontSize: 22 }} />{" "}
                      {/* Blue check-circle icon */}
                    </ListItemIcon>
                    <Typography
                      variant="body1"
                      color="black"
                      sx={{ lineHeight: 1.7 }}
                      dangerouslySetInnerHTML={{ __html: term?.description }}
                    />
                  </ListItem>
                </List>
                {index !== termsData?.length - 1 && <Divider sx={{ mb: 4 }} />}
              </Box>
            ))}
          </Paper>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
