import { Box } from "@mui/material";
import React from "react";
import { extractVideoIdAndType, getId } from "../../utils/common";
import { RobotoTypo } from "../../utils/Typographies";
import { useTranslation } from "react-i18next";

const NewsInformationBody = ({ data }) => {
  const { videoId, type } = extractVideoIdAndType(data?.url);
  const { t } = useTranslation();

  const embedUrl =
    type === "shorts"
      ? `https://www.youtube.com/embed/${videoId}`
      : type === "normal"
      ? `https://www.youtube.com/embed/${videoId}`
      : null;
  return (
    <Box
      display="flex"
      gap={"50px"}
      sx={{ px: { xs: "20px", md: "60px" }, py: "60px" }}
      justifyContent={"center"}
      flexDirection={{ xs: "column", md: "row" }}
      alignItems={"center"}
    >
      <Box
        sx={{
          position: "relative",
          width: { xs: "100%", lg: "60%" },
          height: "350px",
        }}
      >
        <iframe
          style={{
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
          width="600px"
          src={data?.url ? embedUrl : ""}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </Box>
    </Box>
  );
};

export default NewsInformationBody;
