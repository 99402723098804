import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import { Clear } from "@mui/icons-material";
import Admin from "../Dashboard";
import { RobotoTypo } from "../../../utils/Typographies";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

export default function Carousel() {
  const [images, setImages] = useState([]);
  const [data, setData] = useState({
    title_en: "",
    description_en: "",
    title_sch: "",
    description_sch: "",
    title_ch: "",
    description_ch: "",
    url: "",
  });
  const [dataError, setDataError] = useState({
    title_en: "",
    description_en: "",
    title_sch: "",
    description_sch: "",
    title_ch: "",
    description_ch: "",
    url: "",
  });
  const [file, setFile] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [fileError, setFileError] = useState("");
  let { carouselId } = useParams();
  const navigate = useNavigate();
  console.log("screenid ", carouselId);
  console.log("data ", data);
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    const imagesArray = files.map((file) => URL.createObjectURL(file));
    setImages((prevImages) => [...prevImages, ...imagesArray]);
    setImages(imagesArray);
    setFile(e.target.files[0]);
    setImageUrl("");
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setFile([]);
  };

  const validate = () => {
    let error = false;
    if (data.title_en == "") {
      setDataError((prev) => ({ ...prev, title_en: "Title is required" }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, title_en: "" }));
      error = false;
    }
    if (data.description_en == "") {
      setDataError((prev) => ({
        ...prev,
        description_en: "Description is required",
      }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, description_en: "" }));
      error = false;
    }
    if (data.title_sch == "") {
      setDataError((prev) => ({ ...prev, title_sch: "Title is required" }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, title_sch: "" }));
      error = false;
    }
    if (data.description_sch == "") {
      setDataError((prev) => ({
        ...prev,
        description_sch: "Description is required",
      }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, description_sch: "" }));
      error = false;
    }
    if (data.title_ch == "") {
      setDataError((prev) => ({ ...prev, title_ch: "Title is required" }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, title_ch: "" }));
      error = false;
    }
    if (data.description_ch == "") {
      setDataError((prev) => ({
        ...prev,
        description_ch: "Description is required",
      }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, description_ch: "" }));
      error = false;
    }
    if (data.url == "") {
      setDataError((prev) => ({ ...prev, url: "Url is required" }));
      error = true;
    } else {
      setDataError((prev) => ({ ...prev, url: "" }));
      error = false;
    }
    console.log("length ", file?.length);
    if (imageUrl == "" && file?.length == 0) {
      setFileError("Please upload image");
      error = true;
    } else {
      setFileError("");
      error = false;
    }
    return error;
  };

  const handleSubmit = () => {
    const validation = validate();
    console.log("va ", validation);
    if (!validation) {
      const form = new FormData();
      if (imageUrl == "") {
        console.log("with");
        form.append("image", file);
      } else {
        console.log("else");
        form.append("image", imageUrl);
      }

      form.append("en_title", data.title_en);
      form.append("en_description", data.description_en);
      form.append("ch_title", data.title_ch);
      form.append("ch_description", data.description_ch);
      form.append("sch_title", data.title_sch);
      form.append("sch_description", data.description_sch);
      form.append("url", data.url);

      if (carouselId) {
        updateCarousel(form);
      } else {
        addCarousel(form);
      }
    }
  };

  const addCarousel = async (reqData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/addCarousel`,
        reqData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 201) {
        navigate("/admin/carousel");
      }
    } catch (error) {
      if (error.response.status == 403) {
      
        navigate("/admin/login");
      }
    }
  };

  const getCarouselById = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getCarouselById/` + id,
        {
          headers: {
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 200) {
        const carouselData = response.data;
        console.log("carouselData ", carouselData);
        setData({
          title_en: carouselData.en.title,
          description_en: carouselData.en.description,
          title_ch: carouselData.ch.title,
          description_ch: carouselData.ch.description,
          url: carouselData.en.url,
          title_sch: carouselData?.sch?.title,
          description_sch: carouselData?.sch?.description,
        });
        setImageUrl(carouselData.en.image);
      }
    } catch (error) {
      if (error.response.status == 403) {
      
        navigate("/admin/login");
      }
    }
  };

  const updateCarousel = async (reqData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/updateCarouselById/` + carouselId,
        reqData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("admin_token"),
          },
        }
      );
      if (response.status == 201) {
        navigate("/admin/carousel");
      }
    } catch (error) {
      if (error.response.status == 403) {
       
        navigate("/admin/login");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
    setDataError((prev) => ({ ...prev, [name]: "" }));
  };

  useEffect(() => {
    if (carouselId) {
      getCarouselById(carouselId);
    }
  }, []);

  return (
    <Admin>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 20, sm: 24 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              English
            </RobotoTypo>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Title
            </RobotoTypo>
            <TextField
              name="title_en"
              value={data.title_en}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.title_en != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                Title
              </RobotoTypo>
            )}
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                my: 1,
              }}
            >
              Description
            </RobotoTypo>
            <TextField
              name="description_en"
              value={data.description_en}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.description_en != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                description
              </RobotoTypo>
            )}
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              URL
            </RobotoTypo>
            <TextField
              name="url"
              value={data.url}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.url != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                url
              </RobotoTypo>
            )}
          </Box>
          <Box sx={{ width: "50%" }}>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 24 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Chinese
            </RobotoTypo>
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Title
            </RobotoTypo>
            <TextField
              name="title_ch"
              value={data.title_ch}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.title_ch != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                Title
              </RobotoTypo>
            )}
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 18, sm: 20 },
                color: "#003B5C",
                fontWeight: 700,
                my: 1,
              }}
            >
              Description
            </RobotoTypo>
            <TextField
              name="description_ch"
              value={data.description_ch}
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={handleInputChange}
            />
            {dataError.description_ch != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                description
              </RobotoTypo>
            )}
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 20, sm: 24 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Simplified Chinese
          </RobotoTypo>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 20 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Title
          </RobotoTypo>
          <TextField
            name="title_sch"
            value={data.title_sch}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={handleInputChange}
          />
          {dataError.title_sch != "" && (
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 14, sm: 18 },
                color: "red",
                fontWeight: 500,
                my: 1,
              }}
            >
              Title
            </RobotoTypo>
          )}
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 20 },
              color: "#003B5C",
              fontWeight: 700,
              my: 1,
            }}
          >
            Description
          </RobotoTypo>
          <TextField
            name="description_sch"
            value={data.description_sch}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={handleInputChange}
          />
          {dataError.description_sch != "" && (
            <RobotoTypo
              sx={{
                textAlign: "center",
                width: "fit-content",
                fontSize: { xs: 14, sm: 18 },
                color: "red",
                fontWeight: 500,
                my: 1,
              }}
            >
              description
            </RobotoTypo>
          )}
        </Box>
        <Box>
          <RobotoTypo
            sx={{
              textAlign: "center",
              width: "fit-content",
              fontSize: { xs: 18, sm: 20 },
              color: "#003B5C",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Upload Carousel Images
          </RobotoTypo>
          <input
            type="file"
            accept="image/*"
            // multiple
            style={{ display: "none" }}
            id="image-input"
            onChange={handleImageChange}
          />
          <label htmlFor="image-input">
            <Button
              component="span"
              variant="contained"
              sx={{
                mb: 2,
                backgroundColor: "#177D84",
                "&:hover": { backgroundColor: "#177D84" },
              }}
            >
              Select Image
            </Button>
            {fileError != "" && (
              <RobotoTypo
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontSize: { xs: 14, sm: 18 },
                  color: "red",
                  fontWeight: 500,
                  my: 1,
                }}
              >
                {fileError}
              </RobotoTypo>
            )}
          </label>
          <Grid container spacing={2}>
            {imageUrl ? (
              <Grid item xs={6} md={4}>
                <Box style={{ position: "relative" }}>
                  <Box
                    component="img"
                    src={`${process.env.REACT_APP_FILE_URL}${imageUrl}`}
                    alt={`Preview ${imageUrl}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <IconButton
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={() => setImageUrl("")}
                  >
                    <Clear />
                  </IconButton>
                </Box>
              </Grid>
            ) : (
              images?.map((image, index) => (
                <Grid item key={index} xs={6} md={4}>
                  <Box style={{ position: "relative" }}>
                    <Box
                      component="img"
                      src={image}
                      alt={`Preview ${index}`}
                      style={{ width: "100%", height: "auto" }}
                    />
                    <IconButton
                      style={{ position: "absolute", top: 0, right: 0 }}
                      onClick={() => handleRemoveImage(index)}
                    >
                      <Clear />
                    </IconButton>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
        <Button
          component="span"
          onClick={handleSubmit}
          variant="contained"
          sx={{
            alignSelf: "center",
            mt: 1,
            width: "fit-content",
            backgroundColor: "#177D84",
            "&:hover": { backgroundColor: "#177D84" },
          }}
        >
          {carouselId ? "Edit" : "Add"}
        </Button>
      </Box>
    </Admin>
  );
}
