import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import Admin from "../Dashboard";
import { RobotoTypo } from "../../../utils/Typographies";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function StudentList() {
  const navigate = useNavigate();
  const [StudentsList, setStudentsList] = useState([]);
  const [value, setValue] = React.useState(0);

  const getCarousel = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getAllStudent`
      );
      console.log("response get ", response);
      if (response.status == 200) {
        setStudentsList(response.data);
      } else if (response.status == 403) {
        navigate("/admin/login");
      }
    } catch (error) {
      console.log("er ", error);
    }
  };

  useEffect(() => {
    getCarousel();
  }, []);
  console.log("StudentsList ", StudentsList);
  const handleEdit = (id) => {
    navigate(`/admin/studentDetails/${id}`);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <Admin>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Pay Now" {...a11yProps(0)} />
            <Tab label="Pay Later" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sno</TableCell>
                <TableCell>Institute Name</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {StudentsList.filter(
                (val) => value === 0 && val?.StudentDetails?.payment_details
              ).map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {row?.StudentDetails?.personalDetails?.school_name ||
                      row?.StudentDetails?.personalDetails?.selected_school}
                  </TableCell>
                  <TableCell>
                    {row?.StudentDetails?.personalDetails?.name ||
                      row?.StudentDetails?.personalDetails?.student_name}
                  </TableCell>
                  <TableCell>
                    {row?.StudentDetails?.personalDetails?.email}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        handleEdit(row._id);
                      }}
                      aria-label="edit"
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {StudentsList.filter(
                (val) => value === 1 && !val?.StudentDetails?.payment_details
              ).map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {row?.StudentDetails?.personalDetails?.school_name ||
                      row?.StudentDetails?.personalDetails?.selected_school}
                  </TableCell>
                  <TableCell>
                    {row?.StudentDetails?.personalDetails?.name ||
                      row?.StudentDetails?.personalDetails?.student_name}
                  </TableCell>
                  <TableCell>
                    {row?.StudentDetails?.personalDetails?.email}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        handleEdit(row._id);
                      }}
                      aria-label="edit"
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Admin>
  );
}
