import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { RobotoTypo } from "../../../utils/Typographies";
import { useTranslation } from "react-i18next";
import { ReactComponent as YellowDot } from "../../../assets/Home/ApplicationProcess/YellowDot.svg";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const useStyles = makeStyles({
  customAccordion: {
    "&.MuiPaper-root.MuiAccordion-root:before": {
      backgroundColor: "white!important",
    },
    borderRadius: "20px", // Add additional styles for the Accordion itself
    backgroundColor: "transparent", // Add additional styles for the Accordion itself
  },
});

const theme = createTheme({
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          border: "1px solid black",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "1px solid black",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          border: "1px solid black",
        },
      },
    },
  },
});

export default function PartnerUniversity({ data }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  console.log("data?.partnerUniversity[0].title ", data);
  return data?.partnerUniversity?.length == 1 &&
    (data?.partnerUniversity[0].title == "" ||
      data?.partnerUniversity[0].description == "") ? (
    ""
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFF",
        pb: 7,
      }}
    >
      <Box
        sx={{
          alignSelf: "center",
          my: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#003B5C",
          padding: "8px 12px",
          borderRadius: "50px",
          width: "fit-content",
        }}
      >
        <RobotoTypo
          sx={{
            letterSpacing: "0.02em",
            fontWeight: "700",
            color: "white",
            fontSize: { xs: "20px", sm: "22px", md: "22px", lg: "22px" },
            textAlign: "left",
          }}
        >
          {t("School.PartnerUniversity", { returnObjects: true }).title}
        </RobotoTypo>
        <br />
      </Box>
      <Box
        sx={{
          width: "90%",
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <ThemeProvider theme={theme}>
          <TableContainer component={Paper}>
            <Table>
              {/* <TableHead>
        <TableRow>
          <TableCell>Logo</TableCell>
          <TableCell>Details</TableCell>
        </TableRow>
      </TableHead> */}
              <TableBody>
                {data?.partnerUniversity?.map((university, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "none",
                      }}
                    >
                      {university?.logo_url && (
                        <img
                          className={classes.headerImage}
                          src={`${process.env.REACT_APP_FILE_URL}${university?.logo_url}`}
                          alt={`${university.title}`}
                          style={{
                            objectFit: "contain",
                            height: "100px",
                            borderBottomLeftRadius: {
                              xs: "0",
                              md: "0",
                              lg: "0",
                              xl: "50px",
                              xxl: "50px",
                            },
                            width: "100px",
                            position: {
                              xs: "relative",
                              md: "relative",
                              lg: "absolute",
                              xl: "absolute",
                              xxl: "absoulte",
                            },
                            right: "0",
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Box
                        mb={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <YellowDot style={{ width: "10px", height: "10px" }} />
                        <RobotoTypo
                          sx={{ color: "#003b5c", fontWeight: "bolder" }}
                        >
                          {university.title}
                        </RobotoTypo>
                      </Box>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: university.description,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ThemeProvider>
        {/* {data?.courses_detail?.map((item,index) => (
              <Accordion
              expanded={expanded === index}
              onChange={handleChange(index)}
              classes={classes.customAccordion} sx={{'&::before': { backgroundColor: '#F7F7F7' }}} style={{borderRadius:'20px'}}>
              <AccordionSummary
               expandIcon={expanded === index ? <Minus style={{width:'30px'}}/> : <Plus style={{width:'20px'}}/>}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ padding:'10px 20px 0px 20px'}}
              >
              <RobotoTypo sx={{fontWeight:'500', lineHeight:'30px', letterSpacing:'8%', fontSize: { xs: '18px', sm: '20px', md: '21px', lg: '21px' }, color:'#003B5C'}}>{item.title}</RobotoTypo>
              </AccordionSummary>
              <AccordionDetails>
              <RobotoTypo sx={{fontWeight:'500', lineHeight:'28px', letterSpacing:'8%', fontSize:'16px', borderTop:'1px solid gray', paddingTop:'20px',color:'#242424'}}>
                  {<p dangerouslySetInnerHTML={{ __html: item.description }} />}
              </RobotoTypo>
              </AccordionDetails>
          </Accordion>
          )) 
          } */}
      </Box>
    </Box>
  );
}
