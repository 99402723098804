import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  RiHome4Line,
  RiCarouselView,
  RiSchoolLine,
  RiNewsLine,
  RiTeamLine,
} from "react-icons/ri";
import { PiStudent } from "react-icons/pi";
import { FaRegFileLines } from "react-icons/fa6";
import ListItemText from "@mui/material/ListItemText";
import Logo from "../../utils/Logo";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import LogoutIcon from "@mui/icons-material/Logout";
import { makeStyles } from "@mui/styles";
import { BaskerTypo } from "../../utils/Typographies";
import { NavLink, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  submenuOpen: {
    maxHeight: "1000px", // Set to a large value to accommodate varying submenu heights
    transition: "max-height 0.3s ease-in-out", // Adjust the transition duration as needed
  },
  submenuClosed: {
    maxHeight: "0",
    overflow: "hidden",
    transition: "max-height 0.3s ease-in-out", // Adjust the transition duration as needed
  },
}));

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const menuItems = [
  {
    parent: "Main page",
    parentIcon: <RiHome4Line style={{ width: "20px", height: "20px" }} />,
    subMenus: [
      {
        data: "Carousel",
        route: "/admin/carousel",
        subMenuIcon: (
          <RiCarouselView style={{ width: "20px", height: "20px" }} />
        ),
      },
    ],
  },
  {
    parent: "School",
    parentIcon: <RiSchoolLine style={{ width: "20px", height: "20px" }} />,
    subMenus: [
      {
        data: "Language Course",
        route: "/admin/languageCourse",
        subMenuIcon: <RiSchoolLine style={{ width: "20px", height: "20px" }} />,
      },
      {
        data: "Childcare & Kindergarten",
        route: "/admin/kindergarden",
        subMenuIcon: <RiSchoolLine style={{ width: "20px", height: "20px" }} />,
      },
      {
        data: "Primary School",
        route: "/admin/primarySchool",
        subMenuIcon: <RiSchoolLine style={{ width: "20px", height: "20px" }} />,
      },
      {
        data: "Secondary and High School",
        route: "/admin/secondarySchool",
        subMenuIcon: <RiSchoolLine style={{ width: "20px", height: "20px" }} />,
      },
      {
        data: "International School",
        route: "/admin/internationalSchool",
        subMenuIcon: <RiSchoolLine style={{ width: "20px", height: "20px" }} />,
      },
      {
        data: "University",
        route: "/admin/university",
        subMenuIcon: <RiSchoolLine style={{ width: "20px", height: "20px" }} />,
      },
    ],
  },
  {
    parent: "News",
    parentIcon: <RiNewsLine style={{ width: "20px", height: "20px" }} />,
    subMenus: [
      {
        data: "News",
        route: "/admin/news",
        subMenuIcon: <RiNewsLine style={{ width: "20px", height: "20px" }} />,
      },
    ],
  },
  {
    parent: "Teams",
    parentIcon: <RiTeamLine style={{ width: "20px", height: "20px" }} />,
    subMenus: [
      {
        data: "Our Team",
        route: "/admin/higherOfficial",
        subMenuIcon: <RiTeamLine style={{ width: "20px", height: "20px" }} />,
      },
      {
        data: "Singapore Team",
        route: "/admin/singaporeTeam",
        subMenuIcon: <RiTeamLine style={{ width: "20px", height: "20px" }} />,
      },

      {
        data: "Hongkong Team",
        route: "/admin/hongKongTeam",
        subMenuIcon: <RiTeamLine style={{ width: "20px", height: "20px" }} />,
      },
      {
        data: "China Team",
        route: "/admin/chinaTeam",
        subMenuIcon: <RiTeamLine style={{ width: "20px", height: "20px" }} />,
      },
    ],
  },
  {
    parent: "Students",
    parentIcon: <PiStudent style={{ width: "20px", height: "20px" }} />,
    subMenus: [
      {
        data: "Applications",
        route: "/admin/StudentList",
        subMenuIcon: <PiStudent style={{ width: "20px", height: "20px" }} />,
      },
    ],
  },
  {
    parent: "Services",
    parentIcon: <PiStudent style={{ width: "20px", height: "20px" }} />,
    subMenus: [
      {
        data: "Extracurricular Activities",
        route: "/admin/ExtraCurricularActivities",
        subMenuIcon: <PiStudent style={{ width: "20px", height: "20px" }} />,
      },
      {
        data: "Enrollment Application",
        route: "/admin/enrollmentApplication",
        subMenuIcon: <PiStudent style={{ width: "20px", height: "20px" }} />,
      },
    ],
  },
  {
    parent: "Terms and Condition",
    parentIcon: <FaRegFileLines style={{ width: "20px", height: "20px" }} />,
    subMenus: [
      {
        data: "Terms and Conditions",
        route: "/admin/terms",
        subMenuIcon: (
          <FaRegFileLines style={{ width: "20px", height: "20px" }} />
        ),
      },
    ],
  },
  {
    parent: "Privacy Policy",
    parentIcon: <FaRegFileLines style={{ width: "20px", height: "20px" }} />,
    subMenus: [
      {
        data: "privacy Policy",
        route: "/admin/privacyPolicy",
        subMenuIcon: (
          <FaRegFileLines style={{ width: "20px", height: "20px" }} />
        ),
      },
    ],
  },
];

export default function Admin({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openSubMenus, setOpenSubMenus] = React.useState({
    "Main page": true, // Set the 'Main page' submenu to be open by default
  });
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = React.useState(null);
  const navigate = useNavigate();

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleSubMenu = (parent) => {
    setOpenSubMenus((prevOpenSubMenus) => {
      const updatedOpenSubMenus = { ...prevOpenSubMenus };

      // Close all submenus except the one being toggled
      for (const menu in updatedOpenSubMenus) {
        if (menu !== parent) {
          updatedOpenSubMenus[menu] = false;
        }
      }

      // Toggle the state of the clicked menu item
      updatedOpenSubMenus[parent] = !prevOpenSubMenus[parent];

      return updatedOpenSubMenus;
    });
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/admin/login");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar sx={{ backgroundColor: "#177D84" }} position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              // marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ height: "96px" }}>
            {!open && <Logo style={{ height: "35px", width: "35px" }} />}
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Drawer variant="permanent" open={open}>
          <DrawerHeader
            sx={{
              backgroundColor: "#177D84",
              height: "15vh",
              width: "100%",
              alignSelf: "self-start",
              justifyContent: "center",
              gap: "50px",
            }}
          >
            {open && (
              <Box
                style={{ height: "64px" }}
                sx={{ display: "grid", placeItems: "center" }}
              >
                <BaskerTypo
                  sx={{ fontSize: "35px", color: "white", fontWeight: 500 }}
                >
                  DR
                </BaskerTypo>
                <BaskerTypo
                  sx={{
                    fontSize: "12px",
                    color: "white",
                    fontWeight: 500,
                  }}
                >
                  Pacific Holdings
                </BaskerTypo>
              </Box>
            )}
            {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon style={{ color: 'white' }} /> : <ChevronLeftIcon style={{ color: 'white' }} />}
          </IconButton> */}
          </DrawerHeader>
          <Divider />
          <Box height="85vh" sx={{ overflowY: "auto" }}>
            <List sx={{ marginTop: open ? "5px" : "10px" }}>
              {menuItems?.map((menuItem, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    disablePadding
                    onClick={() => toggleSubMenu(menuItem.parent)}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {/* <InboxIcon /> */}
                        {menuItem.parentIcon}
                      </ListItemIcon>
                      <ListItemText
                        primary={menuItem.parent}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                      {openSubMenus[menuItem.parent] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItemButton>
                  </ListItem>
                  <List
                    className={
                      openSubMenus[menuItem.parent]
                        ? classes.submenuOpen
                        : classes.submenuClosed
                    }
                  >
                    {menuItem?.subMenus?.map((subMenu, subIndex) => (
                      <NavLink
                        key={subIndex}
                        to={subMenu.route}
                        style={{
                          textDecoration: "none",
                          color: "none",
                          backgroundColor: "white",
                        }}
                      >
                        <ListItem
                          key={subIndex}
                          disablePadding
                          sx={{ ml: open ? "45px" : "0px" }}
                        >
                          <ListItemButton
                            selected={selectedItem === subMenu.data}
                            onClick={() => handleItemClick(subMenu.data)}
                            sx={{
                              backgroundColor: "white",
                              "&:focus": { backgroundColor: "lightgray" },
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: "30px" }}>
                              {/* <InboxIcon /> */}
                              {subMenu?.subMenuIcon}
                            </ListItemIcon>
                            <ListItemText
                              primary={subMenu.data}
                              sx={{
                                color: "rgba(0, 0, 0, 0.87)",
                                maxWidth: "160px",
                                overflow: "hidden",
                                wordWrap: "break-word",
                                textWrap: "wrap",
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      </NavLink>
                    ))}
                  </List>
                </React.Fragment>
              ))}
            </List>
            <Box sx={{ width: "100%" }}>
              <ListItem disablePadding onClick={() => handleLogout()}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <LogoutIcon />
                      <ListItemText
                        primary={open ? "Logout" : ""}
                        sx={{ color: "rgba(0, 0, 0, 0.87)" }}
                      />
                    </Box>
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Box>
          </Box>
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: "30px" }}>
        <DrawerHeader />
        <Box>{children}</Box>
      </Box>
    </Box>
  );
}
